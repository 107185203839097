import { createSlice } from '@reduxjs/toolkit';
import { ISawList } from '@models';
import { RootState } from '@store';

const initialState: ISawList = {
    uuid: '',
    priceInclVat: 0,
    priceExclVat: 0,
    vat: 0,
    vatPrice: 0,
    createDate: '',
    updateDate: '',
    items: [],
    isLoading: true
};

export const sawListSlice = createSlice({
    name: 'sawList',
    initialState,
    reducers: {
        setSawList: (state, action) => {
            for(const [key, value] of Object.entries(initialState)) {
                state[key] = action.payload[key]
            }
            state.isLoading = false;
        },
        setIsSawListLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    }
});

export const {
    setIsSawListLoading,
    setSawList
} = sawListSlice.actions;

export const selectIsSawListLoading = (state: RootState) => state.sawList.isLoading;

export const selectSawList = (state: RootState) => state.sawList;

export default sawListSlice.reducer;