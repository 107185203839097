import { get, set } from 'lodash';
import { prepareLocale } from './commonHelper';

export default class MicrocopyHelper {
    private items = {};

    constructor(contentfulResourceSet, private locale: string) {
        this.prepare(contentfulResourceSet);

        return this;
    }

    public add(contentfulResourceSet) {
        this.prepare(contentfulResourceSet);

        return this;
    }

    public get(key: string, defaultValue: string = '') {
        // if(!get(this.items, [ this.locale, key ])?.value) console.log(`{ key: '${key}', value: '${defaultValue}' },`);

        return get(this.items, [ this.locale, key ])?.value ?? defaultValue;
    }

    private prepare(nodes) {
        nodes.forEach(node => {
            node.resources.forEach(item => {
                set(this.items, [ prepareLocale(item.node_locale), item.key ], {
                    value: item.value?.value
                });
            });
        }, this.items);
    }
}
