import React from 'react';
import { Box, FormControl, FormHelperText, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import Slider from '@mui/material/Slider';
import { getPrice, getPriceCents, Price, priceSymbol } from '@components/Common';
import { useMicrocopy } from '@hooks/common';
import { RetailerComponent } from '@components';

const PriceFilterControls = ({
    minPriceValue,
    maxPriceValue,
    onMinPriceChange,
    onMaxPriceChange,
    min,
    max,
    onSliderChange,
    onChangeCommitted
}) => {
    const microcopy = useMicrocopy();

    return (
        <Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={ 2 } sx={ { flex: 1 } }
            >
                <FormControl variant="outlined" aria-label="price-slider-inputs">
                    <FormHelperText aria-label="price-slider-helper-text" id="outlined-min-price-helper-text">
                        { microcopy.get('catalog.filter.price.from', 'from') }
                    </FormHelperText>
                    <OutlinedInput
                        id="outlined-adornment-min-price"
                        value={ getPrice(minPriceValue) }
                        onChange={ event => onMinPriceChange(getPriceCents(Number(event.target.value))) }
                        name="min-price"
                        aria-describedby="outlined-price-helper-text"
                        inputProps={ {
                            step: 1,
                            min: getPrice(min),
                            max: getPrice(max),
                            type: 'number',
                            sx: { pr: 1 / 2 },
                            'aria-label': 'price-slider-input'
                        } }
                        startAdornment={
                            <InputAdornment aria-label="min-price-adornment" position="start">
                                { priceSymbol }
                            </InputAdornment>
                        }
                        disabled={ true }
                    />
                </FormControl>
                <FormControl variant="outlined" aria-label="price-slider-inputs">
                    <FormHelperText aria-label="price-slider-helper-text" id="outlined-max-price-helper-text">
                        { microcopy.get('catalog.filter.price.to', 'to') }
                    </FormHelperText>
                    <OutlinedInput
                        id="outlined-adornment-max-price"
                        value={ getPrice(maxPriceValue) }
                        onChange={ event => onMaxPriceChange(getPriceCents(Number(event.target.value))) }
                        name="max-price"
                        inputProps={ {
                            step: 1,
                            min: getPrice(min),
                            max: getPrice(max),
                            type: 'number',
                            sx: { pr: 1 / 2 },
                            'aria-label': 'price-slider-input'
                        } }
                        aria-describedby="outlined-price-helper-text"
                        startAdornment={
                            <InputAdornment aria-label="max-price-adornment" position="start">
                                { priceSymbol }
                            </InputAdornment>
                        }
                        disabled={ true }
                    />
                </FormControl>
            </Stack>
            <Box pr={ '10px' } pl={ '10px' } className="price-slider">
                <Slider
                    aria-labelledby="input-slider"
                    getAriaLabel={() => 'input-slider'}
                    min={ min }
                    max={ max }
                    value={ [ minPriceValue, maxPriceValue ] }
                    onChange={ onSliderChange }
                    onChangeCommitted={ onChangeCommitted }
                    disableSwap
                    disabled={ min === max }
                    marks={ [
                        { value: min, label: <Price price={ min }/> },
                        { value: max, label: <Price price={ max }/> }
                    ] }
                />
            </Box>
        </Stack>
    );
};

export default (props: {
    minPriceValue: number,
    maxPriceValue: number,
    onMinPriceChange: any,
    onMaxPriceChange: any,
    min: number,
    max: number,
    onSliderChange: any,
    onChangeCommitted: any
}) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Catalog/Filters',
            name: 'PriceFilterControls',
            element: PriceFilterControls
        } }
    />;
