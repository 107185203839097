import React from 'react';
import { RetailerComponent } from '@components';
import { useCommonHeaderSkeleton } from '@hooks/skeleton/common';

const Header = props => (<></>);

export default (props: { pageContext: any }) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Common',
            name: 'Header',
            element: Header,
        } }
        skeleton={ useCommonHeaderSkeleton() }
    />;
