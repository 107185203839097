import { Box, Typography } from '@mui/material';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const TooltipPopover = ({ title, content, innerBorderRef, onClose }: any) => {
    return (
        <Box ref={ innerBorderRef } className="tooltip-popover">
            <Typography className="tooltip-popover-title" component="h2">
                { title }
            </Typography>
            <Typography component="div" className="tooltip-popover-content">
                { typeof content === 'string' ? content : renderRichText(content) }
            </Typography>
            <IconButton className="tooltip-popover-close" onClick={ onClose }>
                <CloseIcon/>
            </IconButton>
        </Box>
    );
};

export default TooltipPopover;
