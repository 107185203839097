import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMicrocopy } from '@hooks/common';

const SortingFilter = ({ values, selected, handleChange }) => {
    const microcopy = useMicrocopy();

    const [ isSelectOpen, setSelectOpenState ] = useState(false);

    return (
        <FormControl className="sorting">
            <InputLabel id="sorting-select-label">
                { microcopy.get('catalog.filter.sorting', 'Sorting') }
            </InputLabel>
            <Select
                labelId="sorting-select-label"
                id="sorting-select"
                value={ selected }
                label={ microcopy.get('catalog.filter.sorting', 'Sorting') }
                onChange={ event => handleChange(event.target.value) }
                onOpen={ () => { setSelectOpenState(true); } }
                onClose={ () => { setSelectOpenState(false); } }
                IconComponent={ () => isSelectOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
                sx={ { minWidth: 160 } }
                MenuProps={ {
                    autoFocus: false,
                    disableAutoFocusItem: true,
                    disableEnforceFocus: true,
                    disableAutoFocus: true
                } }
            >
                { Object.keys(values).map(key =>
                    (<MenuItem key={ `sorting_value_${ key }` } value={ values[key] }>{ key }</MenuItem>))
                }
            </Select>
        </FormControl>
    );
};

export default SortingFilter;
