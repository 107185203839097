import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import {Button, Collapse, Divider, Stack, ThemeProvider} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useIsMobile, useLocale, useTheme } from '@hooks/common';
import { RetailerComponent } from '@components';
import { useTitleCollapseSkeleton } from '@hooks/skeleton/common';
import { useActiveFilters } from '@hooks/catalog';
import { get } from 'lodash';

const TitleCollapse = ({
    children,
    title,
    tooltip = null,
    expandIcon = <ExpandLessIcon/>,
    hideIcon = <ExpandMoreIcon/>,
    filterCode = undefined,
    onFold = isOpened => {},
    opened = undefined
}) => {
    const
        theme = useTheme(),
        isMobile = useIsMobile(),
        locale = useLocale(),
        activeFilters = useActiveFilters();

    const getOpened = () => isMobile ? !!get(activeFilters, `${ filterCode }_${ locale }`) : true;

    const [ open, setOpen ] = useState(opened ?? getOpened());

    useEffect(() => setOpen(opened ?? getOpened()), [ activeFilters ]);

    return (
        <Stack className="title-collapse">
            <ThemeProvider theme={ theme }>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Button
                        aria-label="title-collapse"
                        startIcon={ open ? hideIcon : expandIcon }
                        onClick={ () => {
                            onFold(!open);
                            setOpen(!open);
                        } }
                    >
                        { title }
                    </Button>
                    { tooltip }
                </Stack>
                <Divider aria-label="title-collapse-divider" sx={ { display: 'none' } }/>
            </ThemeProvider>
            <Collapse in={ open } timeout="auto" unmountOnExit sx={ { pb: '35px' } }>
                { children }
            </Collapse>
        </Stack>
    );
};

export default (props: {
    children: ReactNode,
    title: string,
    tooltip?: ReactElement | any,
    expandIcon?: ReactElement,
    hideIcon?: ReactElement,
    filterCode?: string,
    onFold?: any,
    opened?: boolean
}) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Common',
            name: 'TitleCollapse',
            element: TitleCollapse,
        } }
        skeleton={ useTitleCollapseSkeleton() }
    />;
