import React from 'react';
import { Stack, Button } from '@mui/material';
import { useMicrocopy } from '@hooks/common';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const CloseTooltipDialogButton = ({ handleClick }) => {
    const microcopy = useMicrocopy();

    return (
        <Stack flexDirection="row" justifyContent="flex-end">
            <Button onClick={ handleClick } sx={ { mr: -1.5 } }>
                { microcopy.get('catalog.filter.clear_filter', 'Clear filter') }
                <ArrowForwardIosIcon sx={ { ml: 1, fontSize: '1rem' } }/>
            </Button>
        </Stack>
    );
};

export default CloseTooltipDialogButton;
