import React from 'react';
import { useQueryParam } from 'use-query-params';
import { Box, Stack } from '@mui/material';
import UrlHelper from '@helpers/urlHelper';
import { useLocale, useRetailer, useRetailerLike } from '@hooks/common';
import { retailers } from '@helpers';
import vdmWhiteLogo from '@images/retailer/Praxis/vdm-white.svg';
import PraxisLogo from '@images/retailer/Praxis/praxis.svg';
import BricoLogo from '@images/retailer/Praxis/brico.svg';

const Header = () => {
    const
        retailer = useRetailer(false),
        locale = useLocale(),
        isBrico = useRetailerLike(retailers.Brico.name);

    const [ inStore ] = useQueryParam('inStore');

    const hrefProps = {
        href: inStore === 'true'
            ? (UrlHelper.getUrl(retailer, locale) + '?inStore=' + inStore)
            : (isBrico ? 'https://www.brico.be' : 'https://www.praxis.nl'),
        target: inStore === 'true' ? '_self' : '_parent',
    };

    return (
        <header>
            <Stack className="header-top">
                <Box className="logo">
                    <a { ...hrefProps }>
                        <img src={ vdmWhiteLogo } alt="Voor de makers"/>

                        <img src={ isBrico ? BricoLogo : PraxisLogo } alt={ `${ retailer } logo` }/>
                    </a>
                </Box>
            </Stack>
        </header>
    );
};

export default Header;
