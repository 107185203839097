import React from 'react';
import { Box, Collapse, Stack, Typography } from '@mui/material';
import { retailers } from '@helpers';
import { useRetailerLike } from '@hooks/common';
import { Skeleton } from '@components/Common';
import { useFilterTooltipSkeleton } from '@hooks/skeleton/catalog';
import { default as MaxedaHeader } from '@components/Common/retailer/Praxis/Header';
import { default as HuboNlHeader } from '@components/Common/retailer/HuboNl/Header';

export const useShowMoreButtonSkeleton = () => {
    if (useRetailerLike([ 'Gamma', 'GammaBe', 'Karwei' ]))
        return <Skeleton variant="text" width={ 100 } height={ 25 }/>;

    return <Skeleton variant="text" width={ 100 } height={ 25 } sx={ { mt: 1 } }/>;
};

export const usePriceSkeleton = () => (
    <Typography component="span" variant="h5" sx={ { lineHeight: 1 } }>
        <Skeleton width={ 50 } height={ 30 }/>
    </Typography>
);

export const useTitleCollapseSkeleton = () => {
    if (useRetailerLike([ 'Gamma', 'GammaBe', 'Karwei' ]))
        return (
            <>
                <Stack direction="row" justifyContent="space-between" sx={ { my: '6px' } }>
                    <Skeleton variant="text" width={ 150 }/>
                    <Skeleton variant="text" width={ 18 }/>
                </Stack>
                <Collapse in={ true } className="gamma-collapse">
                    { useFilterTooltipSkeleton() }
                    <Stack direction="column" sx={ { pb: '35px' } }>
                        <Skeleton variant="text" width={ 150 }/>
                        <Skeleton variant="text" width={ 150 }/>
                        <Skeleton variant="text" width={ 150 }/>
                    </Stack>
                </Collapse>
            </>
        );

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={ { mt: '7px', mb: '9px' } }
            >
                <Skeleton variant="text" width={ 200 } height={ 42 }/>
                { useFilterTooltipSkeleton() }
            </Stack>
            <Stack direction="column" sx={ { pb: '35px' } }>
                <Skeleton variant="text" width={ 150 }/>
                <Skeleton variant="text" width={ 150 }/>
                <Skeleton variant="text" width={ 150 }/>
            </Stack>
        </>
    );
};

export const useCommonHeaderSkeleton = () => {
    if (useRetailerLike([ 'Gamma', 'GammaBe', 'Karwei' ]))
        return (
            <Box className="progress-bar">
                <Stack direction="row" alignItems="flex-start" className="stepper-skeleton">
                    <Stack direction="column" className="stepper-item">
                        <Skeleton variant="circular"/>
                        <Skeleton variant="text"/>
                    </Stack>
                    <Stack direction="column" className="stepper-item">
                        <Skeleton variant="circular"/>
                        <Skeleton variant="text"/>
                        <Skeleton variant="rectangular"/>
                    </Stack>
                    <Stack direction="column" className="stepper-item">
                        <Skeleton variant="circular"/>
                        <Skeleton variant="text"/>
                        <Skeleton variant="rectangular"/>
                    </Stack>
                </Stack>
            </Box>
        );

    if (useRetailerLike(retailers.Brico.name))
        return <MaxedaHeader/>;

    if (useRetailerLike(retailers.HuboNl.name))
        return <HuboNlHeader/>;

    return;
};
