import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@hooks/common';
import { useIsLoading } from '@hooks/catalog';
import { RetailerComponent } from '@components';
import { useProductHeaderSkeleton } from '@hooks/skeleton/catalog';
import { ProductDimensions } from '@components/Catalog';

const ProductHeader = ({ subtitle, title, length, width }) => {
    const theme = useTheme();

    return (
        <Stack direction="column">
            <Stack direction="row" justifyContent="space-between">
                <Box sx={ { pr: '10px' } }>
                    { subtitle &&
                        <Typography className="product-subtitle" sx={ { color: theme.palette.grey[300] } }>
                            { subtitle }
                        </Typography>
                    }
                    <Typography className="product-title">{ title }</Typography>
                </Box>
            </Stack>
            { (!!length && !!width) && <ProductDimensions length={ length } width={ width }/> }
        </Stack>
    );
};

export default (props: {
    subtitle?: string,
    title: string,
    length: number,
    width: number
}) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Catalog',
            name: 'ProductHeader',
            element: ProductHeader
        } }
        isLoading={ useIsLoading() }
        skeleton={ useProductHeaderSkeleton() }
    />;
