import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import FormControl from '@mui/material/FormControl';

const CheckBoxField = ({ title, checked, disabled, onChange }) => {
    return (
        <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
                <FormControlLabel
                    value="end"
                    control={
                        <Checkbox
                            className="checkbox-field"
                            checked={ checked }
                            onChange={ onChange }
                            disabled={ disabled }
                        />
                    }
                    label={ title }
                    labelPlacement="end"
                />
            </FormGroup>
        </FormControl>
    );
};

export default CheckBoxField;
