import React, { useEffect, useState } from 'react';
import { Box, Collapse, Stack } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { isEmpty, pickBy, keys, mapValues, slice, filter, has, split, keyBy } from 'lodash';
import { CheckBoxField, ShowMoreButton } from '@components/Common';
import { mapFilterValue, trackOdinFormStep } from '@helpers';
import { useIsMobile, useMicrocopy, useTheme } from '@hooks/common';
import { useActiveFilters } from '@hooks/catalog';

const AttributesFilter = ({ data, onChange }) => {
    const
        microcopy = useMicrocopy(),
        theme = useTheme(),
        activeFilters = useActiveFilters();

    const ITEMS_TO_SHOW_DEFAULT = !useIsMobile() ? 3 : 100;

    const
        [ isShowMore, setIsShowMore ] = useState(false),
        [ itemToShow, setItemToShow ] = useState(ITEMS_TO_SHOW_DEFAULT),
        [ activeValues, setActiveValues ] = useState<any>([]);

    const filteredDataValues = data.values.filter(item => !isEmpty(item.title));

    const
        handleChange = (title, state) => {
            const values = { ...activeValues, ...{ [title]: state } };
            setActiveValues(values);
            const appliedValues = pickBy(values, value => value);
            onChange(isEmpty(appliedValues) ? null : keys(appliedValues).join(','));
        },
        showMore = () => {
            const isMoreThanDefault = filteredDataValues.length >= ITEMS_TO_SHOW_DEFAULT && !isShowMore;
            setIsShowMore(isMoreThanDefault);
            setItemToShow(isMoreThanDefault ? filteredDataValues.length : ITEMS_TO_SHOW_DEFAULT);
        };

    useEffect(() => {
        const hiddenItems = slice(filteredDataValues, ITEMS_TO_SHOW_DEFAULT, filteredDataValues.length);
        const values = mapValues(
            keyBy(filteredDataValues, 'title'),
            ({ title }) => split(activeFilters[data.name], ',').indexOf(title) >= 0
        );
        const isInHiddenItems = !!filter(hiddenItems, (item: any) => has(values, item.title) && values[item.title]).length;
        setIsShowMore(isInHiddenItems);
        setItemToShow(isInHiddenItems ? filteredDataValues.length : ITEMS_TO_SHOW_DEFAULT);
        setActiveValues(values);
    }, [ activeFilters[data.name] ]);

    return (
        <Box>
            <TransitionGroup>
                { filteredDataValues.slice(0, itemToShow).map(value => (
                    <Collapse key={ `checkbox_field_${ value.title }_${ value.value }` }>
                        <Stack
                            className="facet-value"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <CheckBoxField
                                title={ mapFilterValue(microcopy, data.name, value.title) }
                                checked={ activeValues[value.title] ?? false }
                                disabled={ activeValues[value.title] ? false : value.value === 0 }
                                onChange={ event => handleChange(value.title, event.target.checked) }
                            />
                            <Box
                                component="span"
                                sx={ { color: !activeValues[value.title] ? theme.palette.gray.main : 'initial' } }
                            >
                                { value.value }
                            </Box>
                        </Stack>
                    </Collapse>
                )) }
            </TransitionGroup>
            { filteredDataValues.length > ITEMS_TO_SHOW_DEFAULT &&
                <ShowMoreButton
                    active={ isShowMore }
                    onClick={ () => {
                        showMore();
                        trackOdinFormStep(
                            'toggle',
                            !isShowMore ? 'show_more' : 'show_less',
                            [],
                            { filter_name: data.name }
                        );
                    } }
                />
            }
        </Box>
    );
};

export default AttributesFilter;
