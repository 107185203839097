import React from 'react';
import candoLogo from '@images/cando_logo.svg';
import { useRetailer, useRetailerLogo } from '@hooks/common';

const RetailerLogo = ({ className = '', logo = '' }) => {
    const
        retailer = useRetailer(),
        retailerLogo = useRetailerLogo();

    return (
        <img
            src={ logo.length ? logo : (retailerLogo ?? candoLogo) }
            alt={ retailerLogo ? retailer : (logo.length ? '' : 'Cando') }
            className={ `retailer-logo ${ className }` }
        />
    );
};

export default RetailerLogo;
