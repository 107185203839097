import { alpha } from '@mui/material';

const ThemeWizard = theme => ({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.wizard-btn': {
                        '&.navigation': {
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.primary.main, 0.8),
                            },
                            [theme.breakpoints.down('md')]: {
                                flexBasis: 0,
                                flexGrow: 1,
                                padding: '10px 15px',
                                textAlign: 'center'
                            },
                        },
                        '&.with-icon': {
                            gap: 15
                        },
                        '&.right': {
                            marginLeft: 'auto',
                            backgroundColor: 'red',
                            [theme.breakpoints.down('md')]: {
                                marginLeft: 'unset'
                            }
                        },
                        '&.results': {
                            backgroundColor: theme.palette.wizard.dark,
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.wizard.dark, 0.8),
                            }
                        },
                        '&.disabled': {
                            background: alpha(theme.palette.white.main, 0.3),
                            color: alpha(theme.palette.primary.main, 0.3),
                            borderColor: alpha(theme.palette.black.main, 0.1),
                            borderStyle: 'solid',
                            borderWidth: 1
                        }
                    }
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    '&.answer': {
                        '.MuiCardActionArea-root': {
                            height: '100%',
                            '&:hover, &:active, &:focus, &:visited': {
                                color: 'inherit'
                            }
                        },
                        '&:hover': {
                            '.radio-button': {
                                background: theme.palette.primary.main
                            }
                        },
                        '.radio-button': {
                            display: 'inline-block',
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: theme.palette.primary.main,
                            marginRight: 10,
                            color: theme.palette.white.main,
                            padding: 1
                        }
                    },
                    '&.selected': {
                        '.radio-button': {
                            background: theme.palette.primary.main
                        }
                    }
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '&.answer-content': {
                        width: 300,
                        '.answer-title': {
                            fontSize: '1.5rem',
                        },
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                            '.answer-title': {
                                marginRight: 25,
                            },
                            '.answer-subtitle': {
                                marginLeft: 34,
                                '& > p': {
                                    margin: 0
                                }
                            }
                        }
                    }
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    '&.wizard-container': {
                        background: theme.palette.primary.light,
                        padding: '25px 50px',
                        [theme.breakpoints.down('md')]: {
                            padding: '5px 10px'
                        }
                    }
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '&.wizard': {
                        minHeight: 450,
                        [theme.breakpoints.down('md')]: {
                            minHeight: 'unset'
                        }
                    },
                    '&.wizard-stack.centered': {
                        alignItems: 'center',
                        justifyContent: 'center'
                    },
                    '&.answers': {
                        gap: 16,
                        marginBottom: 16,
                        overflow: 'auto',
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                            gap: 10
                        }
                    },
                    '&.controls': {
                        margin: '20px 0',
                        gap: 25,
                        [theme.breakpoints.down('md')]: {
                            justifyContent: 'space-between'
                        }
                    },
                    '&.control-left': {
                        flexGrow: 1,
                        '& > button:first-of-type': {
                            marginRight: 'auto'
                        }
                    },
                    '&.control-right': {
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                            gap: 10
                        }
                    },
                    '&.control-container': {
                        justifyContent: 'space-between',
                        gap: 25,
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                            gap: 0
                        }
                    },
                    '&.wizard-stack.tooltip': {
                        position: 'absolute',
                        zIndex: 1,
                        right: 8,
                        [theme.breakpoints.down('md')]: {
                            right: 14,
                            top: 14
                        },
                        '& .more-info': {
                            display: 'none'
                        }
                    }
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    '&.wizard-icon.large': {
                        fontSize: 75
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.question-title': {
                        fontSize: '1.5rem',
                        fontWeight: 'bold'
                    },
                    '&.wizard-typography.bold': {
                        fontWeight: 'bold'
                    },
                    '&.large': {
                        fontSize: '1.5em'
                    },
                    '&.radio-button': {
                        fontSize: 14,
                        width: 30,
                        height: 30,
                        borderRadius: '50%',
                        backgroundColor: 'rgba(255,255,255,0.15)',
                        padding: 4
                    }
                }
            }
        }
    }
});

export default ThemeWizard;
