import React, { useState } from 'react';
import { trackGridQuestionTile } from '@helpers';
import { Button, Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useMicrocopy, useRetailer, useRetailerLike } from '@hooks/common';
import GridHelper from '@helpers/gridHelper';
import { IContentfulGridFeedbackTile } from '@models';

const FeedbackTile = ({ data }: { data: IContentfulGridFeedbackTile }) => {
    const
        retailer = useRetailer(),
        microcopy = useMicrocopy();
    
    const
        [ answered, setAnswered ] = useState(false),
        [ value, setValue ] = useState(null),
        [ error, setError ] = useState(false);

    const processUsersFeedback = () => {
        if (!value) {
            setError(true);
            return;
        }
        GridHelper.addIgnoredGridTile(retailer, data.id);
        setAnswered(true);
        trackGridQuestionTile(data.title, data.description, value, data.position);
    };

    return (
        <Card className="grid-feedback-tile">
            <CardContent>
                { !answered
                    ? <Stack direction="column" justifyContent="space-between">
                        <Typography className="grid-feedback-tile-title">
                            { data.title }
                        </Typography>
                        <Typography className="grid-feedback-tile-subtitle">{ data.description }</Typography>
                        <TextField
                            label={ microcopy.get('catalog.grid.question_tile.label', 'Answer...') }
                            multiline
                            onChange={ (event: any) => {
                                setError(false);
                                setValue(event.target.value);
                            } }
                            error={ error }
                            fullWidth={ true }
                            minRows={ 8 }
                            maxRows={ 8 }
                            variant={ useRetailerLike(['Gamma', 'GammaBe', 'Karwei']) ? 'outlined' : 'standard' }
                            className="grid-feedback-tile-input"
                        />
                        <Button
                            variant="contained"
                            onClick={ processUsersFeedback }
                            className="grid-feedback-tile-confirm-button"
                        >
                            { microcopy.get('catalog.grid.question_tile.button', 'Send') }
                        </Button>
                    </Stack>
                    : <Stack alignItems="center" justifyContent="center">
                        <CheckCircleIcon className="grid-feedback-tile-result-icon" fontSize="large"/>
                        <Typography className="grid-feedback-tile-result">
                            { data.thankYouText }
                        </Typography>
                    </Stack>
                }
            </CardContent>
        </Card>
    );
};

export default FeedbackTile;