export default class MultiLanguageHelper {
    constructor(
        private data: any,
        private locale: string
    ) {}

    getField(fieldName: string) {
        return this.data[`${ fieldName }_${ this.locale }`] ?? this.data[fieldName];
    }
}
