import React from 'react';
import { Box, Chip, Grid, Stack } from '@mui/material';
import { useMicrocopy, useNavigate, useTheme } from '@hooks/common';
import { useIsLoading, useRetailerQueryParams } from '@hooks/catalog';
import { useProductVariantsSkeleton } from '@hooks/skeleton/catalog';
import { RetailerComponent } from '@components';

const ProductVariants = ({ variants }) => {
    const
        navigation = useNavigate(),
        microcopy = useMicrocopy(),
        theme = useTheme(),
        retailerQueryParams = useRetailerQueryParams();

    return (Object.keys(variants).length > 0 &&
        <>
            <Box className="product-variants-title" sx={ { color: theme.palette.grey[300], mt: 1 } }>
                { microcopy.get('catalog.product.available_text', 'Also available in') }
            </Box>

            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                mt={ 1 }
                flexWrap="wrap"
                spacing={ 2 }
                className="product-variants"
            >
                <Box>
                    <Grid container spacing={ 1 }>
                        { Object.keys(variants).sort().map(id => (
                            <Grid key={ id } item sx={ { minWidth: '56px', zIndex: 3 } }>
                                <Chip
                                    label={ variants[id] }
                                    variant="outlined"
                                    clickable
                                    onClick={ event => {
                                        event.stopPropagation();

                                        return navigation.toProduct(id, retailerQueryParams);
                                    } }
                                />
                            </Grid>
                        )) }
                    </Grid>
                </Box>
            </Stack>
        </>
    );
};

export default (props: { variants: any }) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Catalog',
            name: 'ProductVariants',
            element: ProductVariants
        } }
        isLoading={ useIsLoading() }
        skeleton={ useProductVariantsSkeleton() }
    />;
