import React from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';
import { pageTypes } from '@helpers';
import { useMicrocopy, useRetailerLike } from '@hooks/common';

const ProgressStepper = ({ pageType }) => {
    const microcopy = useMicrocopy();

    const stepsOrder: string[] = [
        pageTypes.Catalog,
        pageTypes.PDP,
        pageTypes.SawList
    ];

    let steps: string[] = [
        microcopy.get('common.karwei_progressbar_first_step', 'First step'),
        microcopy.get('common.karwei_progressbar_second_step', 'Second step'),
        microcopy.get('common.karwei_progressbar_third_step', 'Third step'),
    ];

    if (useRetailerLike('Stairs'))
        steps = [
            microcopy.get('common.ig_stairs_progressbar_first_step', 'First step'),
            microcopy.get('common.ig_stairs_progressbar_second_step', 'Second step'),
            microcopy.get('common.ig_stairs_progressbar_third_step', 'Third step'),
        ];

    return (
        <Stepper activeStep={ stepsOrder.indexOf(pageType) } alternativeLabel>
            { steps.map(label => (
                <Step key={ label }>
                    <StepLabel className="progress-stepper-label">{ label }</StepLabel>
                </Step>
            )) }
        </Stepper>
    );
};

export default ProgressStepper;
