import React from 'react';
import { Divider, List } from '@mui/material';
import { find, omitBy, split, includes, isNil, every } from 'lodash';
import { PriceFilter, DimensionsFilter, ThicknessFilter, AttributesFilter, ActiveFiltersFilter } from '@components/Catalog/Filters';
import { useLocale, useTheme } from '@hooks/common';
import { useActiveFilters, useRetailerFilters } from '@hooks/catalog';
import Filter from '@components/Catalog/Filters/Filter';
import { mapFilterNameByLocale, trackOdinFormStep, priceFilterMappings } from '@helpers';

const CatalogFilters = ({ filters, onChange, maxDimensions }) => {
    const
        locale = useLocale(),
        theme = useTheme(),
        retailerFilters = useRetailerFilters(),
        activeFilters = useActiveFilters();

    const isEmpty = value =>
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0);

    const
        findFilterByName = name => find(filters, filter => filter.name === name),
        getActiveFilters = (filters: any = null) => omitBy(filters ?? activeFilters, (value, key) => isEmpty(value)),
        updateFilters = filters => onChange({ ...activeFilters, ...filters }),
        mapOdinFormValues = filters => {
            const result = omitBy({ ...activeFilters, ...filters }, value => isNil(value) || value === '');
            if (result.price) {
                result.price_from = result.price[0] ? (result.price[0] / 100) : null;
                result.price_to = result.price[1] ? (result.price[1] / 100) : null;
                delete result.price;
            }

            return omitBy(result, value => isNil(value));
        },
        mapOdinDeltaValues = filters => {
            filters.forEach((filter) => {
                if (filter.field_name === 'price') {
                    filters.push(
                        ...[
                            {
                                field_name: 'price_from',
                                from: filter.from[0] / 100,
                                to: null
                            },
                            {
                                field_name: 'price_to',
                                from: filter.from[1] / 100,
                                to: null
                            }
                        ].filter(item => item.from)
                    );
                }
            });

            return filters.filter(filter => filter.field_name !== 'price');
        };

    return (
        <List aria-label="filter-list">
            { !isEmpty(getActiveFilters()) &&
                <ActiveFiltersFilter
                    onReset={ filter => {
                        const resetValue = filter ? { ...activeFilters, ...{ [filter]: null } } : {};
                        trackOdinFormStep(
                            'change_form',
                            'apply_filter',
                            mapOdinDeltaValues(
                                filter
                                    ? [ {
                                        field_name: filter,
                                        from: activeFilters[filter],
                                        to: null
                                    } ].filter(item => item.from !== item.to)
                                    : Object.keys(activeFilters).map(key => ({
                                        field_name: key,
                                        from: activeFilters[key],
                                        to: null
                                    }))
                            ),
                            filter ? mapOdinFormValues(resetValue) : resetValue
                        );
                        onChange(resetValue);
                    } }
                />
            }

            { retailerFilters.map(filter => {
                let children: any = [];
                switch (filter.type) {
                    case 'Dimension':
                        children.push(<DimensionsFilter
                            key={ `grid_filter_${ filter.code }` }
                            maxDimensions={ maxDimensions }
                            onChange={ value => {
                                const updateValue = {
                                    configurator_input_max_length: value?.length * 10,
                                    configurator_input_max_width: value?.width * 10
                                };

                                trackOdinFormStep(
                                    'change_form',
                                    'apply_filter',
                                    [
                                        {
                                            field_name: 'configurator_input_max_length',
                                            from: activeFilters.configurator_input_max_length ?? null,
                                            to: value.length * 10
                                        },
                                        {
                                            field_name: 'configurator_input_max_width',
                                            from: activeFilters.configurator_input_max_width ?? null,
                                            to: value.width * 10
                                        }
                                    ].filter(item => item.from !== item.to),
                                    mapOdinFormValues(updateValue)
                                );

                                updateFilters(updateValue);
                            } }
                        />);
                        break;
                    case 'Thickness':
                        const thicknessData = findFilterByName('product_thickness');

                        if (!thicknessData?.values) {
                          break;
                        }

                        thicknessData.values = thicknessData.values.filter(item => item.value);
                        if (!thicknessData.values.length)
                            break;

                        children.push(
                            <ThicknessFilter
                                key={ `grid_filter_${ filter.code }` }
                                data={ thicknessData }
                                onChange={ value => {
                                    const
                                        thickness = activeFilters?.product_thickness,
                                        updateValue = {
                                        product_thickness: (includes(thickness, value)
                                            ? split(thickness, ',').filter(item => item !== value)
                                            : split(thickness, ',').filter(item => item.length).concat(value))
                                            .toString()
                                    };
                                    trackOdinFormStep(
                                        'change_form',
                                        'apply_filter',
                                        [ {
                                            field_name: 'product_thickness',
                                            from: thickness ?? null,
                                            to: updateValue.product_thickness.length ? updateValue.product_thickness : null
                                        } ].filter(item => item.from !== item.to),
                                        mapOdinFormValues(updateValue)
                                    );
                                    updateFilters(updateValue);
                                } }
                            />);
                        break;
                    case 'Price':
                        const
                            minPrice = findFilterByName(priceFilterMappings.min_price).values[0].value,
                            maxPrice = findFilterByName(priceFilterMappings.max_price).values[0].value;

                        children.push(
                            <PriceFilter
                                key={ `grid_filter_${ filter.code }` }
                                min={ minPrice }
                                max={ maxPrice }
                                minValue={ activeFilters.price?.[0] }
                                maxValue={ activeFilters.price?.[1] }
                                onChange={ (min_price = undefined, max_price = undefined) => {
                                    const updateValue = { price: [ min_price, max_price ] };

                                    trackOdinFormStep(
                                        'change_form',
                                        'apply_filter',
                                        [
                                            {
                                                field_name: 'price_from',
                                                from: activeFilters['price']?.[0] ?? null,
                                                to: min_price ?? null
                                            },
                                            {
                                                field_name: 'price_to',
                                                from: activeFilters['price']?.[1] ?? null,
                                                to: max_price ?? null
                                            }
                                        ].filter(item => item.from !== item.to),
                                        mapOdinFormValues(updateValue)
                                    );

                                    updateFilters(updateValue);
                                } }
                            />
                        );
                        break;
                    case 'Attribute':
                        const { filterCode, data } = mapFilterNameByLocale(filters, filter.code, locale);

                        if (!data || isEmpty(data.values) || every(data.values, item => !item.value)) return;

                        children.push(
                            <AttributesFilter
                                key={ `grid_filter_attribute_${ filterCode }` }
                                data={ data }
                                onChange={ value => {
                                    const updateValue = { [filterCode]: value };
                                    trackOdinFormStep(
                                        'change_form',
                                        'apply_filter',
                                        [ {
                                            field_name: filterCode,
                                            from: activeFilters[filterCode] ?? null,
                                            to: value
                                        } ],
                                        mapOdinFormValues(updateValue)
                                    );
                                    updateFilters(updateValue);
                                } }
                            />
                        );
                        break;
                }

                return children.length
                    ? <Filter key={ `catalog_filter_${ filter.code }` } content={ filter } values={ children }/>
                    : null;
            }) }
            <Divider sx={ { [theme.breakpoints.up('md')]: { display: 'none' } } }/>
        </List>
    );
};

export default CatalogFilters;
