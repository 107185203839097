import React from 'react';
import { Typography } from '@mui/material';
import { useMicrocopy, useRetailer, useRetailerLike } from '@hooks/common';
import { retailers } from '@helpers';
import { ReactComponent as GammaDiscountIcon } from '@images/retailer/Gamma/discount_card.svg';

export const DiscountIcon = () => {
    if (useRetailerLike([ 'Gamma', 'GammaBe' ]))
        return <GammaDiscountIcon width={ 20 } style={ { margin: '-4 0 0 4' } }/>;

    return;
};

export const DiscountLabel = ({ discount }) => {
    if (!discount)
        return;

    const microcopy = useMicrocopy();

    return (
        <Typography variant="caption" className="promo-label discount-label">
            { `${ discount }% ${ microcopy.get('catalog.price.discount', 'discount') }` }
            <DiscountIcon/>
        </Typography>
    );
};
