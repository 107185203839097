import React from 'react';
import favicon from '@images/icon.png';

export const SEO = ({
    title = 'Microsite configurator',
    icon = favicon,
}) => (
    <>
        <title>{ title }</title>

        <link rel="shortcut icon" href={ icon }/>
    </>
);

export default SEO;
