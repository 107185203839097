import React from 'react';
import { Box, Divider } from '@mui/material';
import { TitleCollapse, Tooltip } from '@components/Common';
import { RetailerComponent } from '@components';
import { useFilterSkeleton } from '@hooks/skeleton/catalog';
import { trackOdinFormStep } from '@helpers';

const Filter = ({ content, values }) => {
    return (
        <Box>
            <Divider/>
            <TitleCollapse
                title={ content.label }
                tooltip={
                    content.tooltip &&
                    <Tooltip title={ content.label } content={ content.tooltip } code={ content.code }/>
                }
                filterCode={ content.code }
                onFold={ value => trackOdinFormStep(
                    'toggle',
                    !value ? 'hide_filter' : 'show_filter',
                    [],
                    { filter_name: content.code }
                ) }
            >
                { content.description &&
                    <Box sx={ { fontSize: '15px', lineHeight: '19px', mt: 1, mb: 3 } }>
                        { content.description }
                    </Box> }
                { values }
            </TitleCollapse>
        </Box>
    );
};

export default (props: {
    content: any,
    values: any
}) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Catalog/Filters',
            name: 'Filter',
            element: Filter
        } }
        skeleton={ useFilterSkeleton(props.values) }
    />;
