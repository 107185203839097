import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

interface BackdropLoaderProps {
    isLoading: boolean;
    invisible?: boolean;
    className?: string;
    sx?: any;
}

const BackdropLoader = (
    {
        isLoading = false,
        invisible = false,
        className = '',
        sx = { zIndex: 100 },
    }: BackdropLoaderProps,
) => {
    if (!isLoading) return;

    return (
        <Backdrop
            open={ isLoading }
            invisible={ invisible }
            className={ className }
            sx={ sx }
        >
            <CircularProgress size={ 60 }/>
        </Backdrop>
    );
};

export default BackdropLoader;