import { v4 as uuid } from 'uuid';
import { getLocalStorage } from './commonHelper';
import { prepareRetailerName } from './retailersHelper';

const
    sawListKey = 'sluid',
    ls = getLocalStorage();

const
    mapKey = retailer => `${ prepareRetailerName(retailer).toLowerCase() }_${ sawListKey }`,
    generateSawListUid = retailer => {
        const key = mapKey(retailer);
        ls.set(key, uuid());

        return ls.get(key);
    };

const SawListHelper = {
    getSawListUid: retailer => ls.get(mapKey(retailer)) ?? generateSawListUid(retailer),
    resetSawListUid: (retailer, newUid = undefined) => {
        if (newUid) {
            ls.set(mapKey(retailer), newUid);

            return newUid;
        }

        ls.remove(mapKey(retailer));

        return generateSawListUid(retailer);
    },
    checkHornbachQueryParams: (retailer: string, hornBachQueryParams: { articleId: string, storeId: string }) => {
        // reset saw-list uuid to allow adding failed configuration again
        if (!hornBachQueryParams.articleId || !hornBachQueryParams.storeId) {
            SawListHelper.resetSawListUid(retailer);
            const hornBachURL = String(process.env.GATSBY_HORNBACH_URL);
            hornBachURL.length && (window.location.href = hornBachURL);
        }
    }
};

export default SawListHelper;
