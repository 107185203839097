import React from 'react';
import { Button } from '@mui/material';
import { useMicrocopy } from '@hooks/common';
import { useIsLoading } from '@hooks/catalog';
import { useGoToProductButtonSkeleton } from '@hooks/skeleton/catalog';
import { RetailerComponent } from '@components';

const GoToProductButton = () => {
    const microcopy = useMicrocopy();

    return (
        <Button variant="contained" aria-label="apply-dimension" className="rounded go-to-product">
            { microcopy.get('catalog.product.link_button', 'See') }
        </Button>
    );
};

export default (props: { id: string }) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Catalog',
            name: 'GoToProductButton',
            element: GoToProductButton
        } }
        isLoading={ useIsLoading() }
        skeleton={ useGoToProductButtonSkeleton() }
    />;
