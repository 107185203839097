// @ts-nocheck
import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ReachAdapter } from 'use-query-params/adapters/reach';
import { GlobalStateProvider } from './src/context/GlobalStateContext';
import { index as store } from './src/store';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
    <Provider store={ store }>
        <QueryParamProvider adapter={ ReachAdapter }>
            <GlobalStateProvider>{ element }</GlobalStateProvider>
        </QueryParamProvider>
    </Provider>
);

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
    window.locations = window.locations || [ document.referrer ];
    if (window.locations[locations.length - 1] !== window.location.href) {
        window.locations.push(window.location.href);
    }
    window.previousPath = window.locations[locations.length - 2];
};
