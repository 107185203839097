import React, { useEffect, useState } from 'react';
import { PriceFilterControls } from '@components/Catalog/Filters';

const PriceFilter = ({ min, max, minValue, maxValue, onChange }) => {
    const
        isMinActive = Boolean(minValue),
        isMaxActive = Boolean(maxValue);

    const [ minPriceValue, setMinPriceValue ] = useState(minValue ?? min),
        [ maxPriceValue, setMaxPriceValue ] = useState(maxValue ?? max),
        [ minValueChanged, setMinValueChanged ] = useState(false),
        [ maxValueChanged, setMaxValueChanged ] = useState(false);

    const handleSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
            switch (activeThumb) {
                case 0:
                    setMinPriceValue(value[activeThumb]);
                    setMinValueChanged(true);
                    break;
                case 1:
                    setMaxPriceValue(value[activeThumb]);
                    setMaxValueChanged(true);
                    break;
            }
        },
        applySliderChange = () => onChange(
            minValueChanged ? minPriceValue : undefined,
            maxValueChanged ? maxPriceValue : undefined
        );

    useEffect(() => {
        setMinPriceValue(isMinActive ? minPriceValue : min);
        setMinValueChanged(isMinActive);
    }, [ min, isMinActive ]);

    useEffect(() => {
        setMaxPriceValue(isMaxActive ? maxPriceValue : max);
        setMaxValueChanged(isMaxActive);
    }, [ max, isMaxActive ]);

    return (
        <PriceFilterControls
            minPriceValue={ minPriceValue }
            maxPriceValue={ maxPriceValue }
            onMinPriceChange={ value => setMinPriceValue(value) }
            onMaxPriceChange={ value => setMaxPriceValue(value) }
            min={ min }
            max={ max }
            onSliderChange={ handleSliderChange }
            onChangeCommitted={ applySliderChange }
        />
    );
};

export default PriceFilter;
