import React from 'react';
import { Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useMicrocopy } from '@hooks/common';
import { RetailerComponent } from '@components';
import { useShowMoreButtonSkeleton } from '@hooks/skeleton/common';

const ShowMoreButton = ({ active, onClick, moreText, lessText }) => {
    const microcopy = useMicrocopy();

    return (
        <Button
            aria-label="show-more"
            onClick={ onClick }
            endIcon={ !active ? <ExpandMoreIcon/> : <ExpandLessIcon/> }
        >
            { !active
                ? moreText ?? microcopy.get('catalog.filter.show_more', 'Show more')
                : lessText ?? microcopy.get('catalog.filter.show_less', 'Show less')
            }
        </Button>
    );
};

export default (props: {
    active: boolean,
    onClick: any,
    moreText?: string,
    lessText?: string
}) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Common',
            name: 'ShowMoreButton',
            element: ShowMoreButton,
        } }
        skeleton={ useShowMoreButtonSkeleton() }
    />;
