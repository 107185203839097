import React, { useRef, useState } from 'react';
import { useMount } from 'react-use';
import { Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import type { ContentfulRichText } from '@models';
import { useIsMobile } from '@hooks/common';
import { RetailerComponent } from '@components';
import { TooltipDialog, TooltipPopover } from '@components/Common';
import { useFilterTooltipSkeleton } from '@hooks/skeleton/catalog';
import { trackOdinFormStep } from '@helpers';

export const useOnOutsideClick = (handleOutsideClick: () => void) => {
    const innerBorderRef = useRef<Element>();

    const onClick = event => {
        // @ts-ignore
        if (innerBorderRef.current && !innerBorderRef.current.contains(event.target)) {
            handleOutsideClick();
        }
    };

    useMount(() => {
        document.addEventListener('click', onClick, true);
        return () => document.removeEventListener('click', onClick, true);
    });

    return { innerBorderRef };
};

const Tooltip = ({
    title,
    content,
    code,
    position = 'right',
    popupOnly = false,
    onClick = isOpen => { isOpen && trackOdinFormStep('toggle', 'filter_tooltip', [], { filter_name: code }); },
}: TooltipProps) => {
    const isMobile = useIsMobile();

    const
        [ open, setOpen ] = useState(false),
        [ dialogPosition, setDialogPosition ] = useState(0);

    const { innerBorderRef } = useOnOutsideClick(() => setOpen(false));

    const handleOpen = iconElement => {
        setOpen(open => {
            onClick(!open);

            return !open;
        });

        const bounding = iconElement.target.getBoundingClientRect();

        setDialogPosition(bounding.top - bounding.height);
    };

    return (
        <Box className={ `tooltip-icon position-${ position }` }>
            <IconButton aria-label="info" sx={ { mr: -1 } } onClick={ handleOpen }>
                <InfoIcon sx={ { width: 24, height: 24 } }/>
            </IconButton>

            { (popupOnly || isMobile) &&
                <TooltipDialog
                    title={ title }
                    content={ content }
                    top={ dialogPosition }
                    open={ open }
                    onClose={ handleOpen }
                />
            }

            { !popupOnly && !isMobile && open &&
                <TooltipPopover
                    innerBorderRef={ innerBorderRef }
                    title={ title }
                    content={ content }
                    onClose={ handleOpen }
                />
            }
        </Box>
    );
};

export interface TooltipProps {
    title: string;
    content: string | ContentfulRichText;
    code?: string;
    withIcon?: boolean;
    withText?: boolean;
    popupOnly?: boolean;
    position?: 'left' | 'right';
    onClick?: (isOpen: boolean) => void;
}

export default (props: TooltipProps) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Common',
            name: 'Tooltip',
            element: Tooltip,
        } }
        skeleton={ useFilterTooltipSkeleton() }
    />;
