import React from 'react';
import { Card, CardActionArea, Stack, Typography } from '@mui/material';
import { useLocale, useNavigate, useRetailer } from '@hooks/common';
import UrlHelper, { urlPageTypes } from '@helpers/urlHelper';
import { trackGridContentTile } from '@helpers';
import { IContentfulGridContentTile } from '@models';
import RichText from 'components/Common/RichText';

const ContentTile = ({ data }: { data: IContentfulGridContentTile }) => {
    const
        retailer = useRetailer(),
        locale = useLocale(),
        navigation = useNavigate();

    const onClick = () => {
        trackGridContentTile(
            data.title,
            data.label?.name,
            `https://${ window.location.host }` + UrlHelper.getUrl(retailer, locale, urlPageTypes.ContentPage, data.contentPageUrl),
            data.position,
        );

        return navigation.toContentPage(data.contentPageUrl);
    };

    return (
        <Card className="grid-content-tile-card" sx={ { backgroundImage: `url(${ data.image })` } }>
            <CardActionArea component="a" onClick={ onClick }>
                <Stack direction="column" justifyContent="space-between">
                    { data.label.name &&
                        <Typography
                            variant="caption"
                            className="grid-content-tile-card-label"
                            sx={ { backgroundColor: data.label.color } }
                        >
                            { data.label.name }
                        </Typography>
                    }
                    <Typography className="grid-content-tile-card-title">
                        { data.title }
                    </Typography>

                    { data.content &&
                        <RichText value={ data.content } className="grid-content-tile-card-description"/>
                    }
                </Stack>
            </CardActionArea>
        </Card>
    );
};

export default ContentTile;
