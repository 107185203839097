import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Typography } from '@mui/material';
import { ContentfulRichText } from '@models';

interface RichTextProps {
    value?: ContentfulRichText,
    className?: string
}

const RichText = ({ value, className }: RichTextProps) => (
    <Typography component="div" className={ className }>
        { value ? renderRichText(value) : null }
    </Typography>
);

export default RichText;
