import React from 'react';
import { Button, Stack } from '@mui/material';
import { useMicrocopy, useNavigate } from '@hooks/common';
import { RetailerComponent } from '@components';
import { useSawListButtonSkeleton } from '@hooks/skeleton/catalog';

const SawListButton = () => {
    const
        navigation = useNavigate(),
        microcopy = useMicrocopy();

    return (
        <Stack direction="row" justifyContent="flex-end" className="sawlist-btn">
            <Button variant="contained" className="rounded" onClick={ () => navigation.toSawList() }>
                { microcopy.get('catalog.grid.sawlist_button', 'Saw List Button') }
            </Button>
        </Stack>
    );
};

export default (props: any) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Catalog',
            name: 'SawListButton',
            element: SawListButton,
        } }
        skeleton={ useSawListButtonSkeleton() }
    />;
