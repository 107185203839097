import React from 'react';
import { Stack, Typography, Avatar } from '@mui/material';
import { split } from 'lodash';
import { prepareLocale, scrollTo, trackOdinFormStep } from '@helpers';
import { useIsMobile, useLocale, useMicrocopy, useRetailerLike, useTheme } from '@hooks/common';
import { useActiveFilters } from '@hooks/catalog';
import { CheckCircleIcon } from '@components/Common';
import { ContentfulRetailerHeaderFilter } from '@models';

interface HeaderContentProps {
    title: string,
    description: string,
    usp: string[],
    filters: ContentfulRetailerHeaderFilter[],
    onChange: any
}

const IGHeaderContent = ({ title }: { title: string }) => (
    <Typography variant="h2" sx={ { mb: '65px', fontWeight: 'bold' } }>
        { title }
    </Typography>
);

const HeaderContent = ({ title, description, usp, filters, onChange }: HeaderContentProps) => {
    const
        locale = useLocale(),
        theme = useTheme(),
        microcopy = useMicrocopy(),
        activeFilters = useActiveFilters(),
        isMobile = useIsMobile();

    if (useRetailerLike([ 'Gamma', 'GammaBe', 'Karwei' ]))
        return useRetailerLike('Stairs')
            ? <IGHeaderContent title={ microcopy.get('catalog.ig_stairs_title', 'Choose your material') }/>
            : <IGHeaderContent title={ microcopy.get('catalog.gamma_title', 'Choose your material') }/>;

    const getFilterCodeWithLocale = (filterCode: string) => `${ filterCode }_${ prepareLocale(locale) }`,
        isFilterActive = (code: string, value: string) => split(activeFilters[getFilterCodeWithLocale(code)], ',').indexOf(value) >= 0,
        handleChange = (code: string, value: string) => {
            trackOdinFormStep(
                'change_form',
                'apply_filter_product_group',
                [ { field_name: 'product_group', from: null, to: value } ],
                { product_group: value }
            );
            onChange(
                !isFilterActive(code, value)
                    ? { [getFilterCodeWithLocale(code)]: value }
                    : { ...activeFilters, [getFilterCodeWithLocale(code)]: null }
            );
            if (isMobile)
                scrollTo(document.getElementById('catalog'));
        };

    return (
        <>
            { title && description &&
                <Stack
                    className="header-content"
                    spacing={ 2 }
                    sx={ { py: 2, [theme.breakpoints.up('md')]: { alignItems: 'center' } } }
                >
                    { title &&
                        <Typography variant="h4" className="header-title">
                            { title }
                        </Typography>
                    }
                    { description &&
                        <Typography variant="h6" className="header-description">
                            { description }
                        </Typography>
                    }
                    { usp &&
                        <Stack
                            direction={ { xs: 'column', md: 'row' } }
                            spacing={ { xs: 1, md: 5 } }
                            className="usp-object"
                            sx={ {
                                py: 2,
                                [theme.breakpoints.up('md')]: {
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }
                            } }
                        >
                            { usp.map(item => (
                                <Stack
                                    direction="row"
                                    spacing={ 1 }
                                    alignItems="center"
                                    key={ `usp_item_${ item }` }
                                >
                                    <CheckCircleIcon/>
                                    <Typography variant="body1">{ item }</Typography>
                                </Stack>
                            )) }
                        </Stack>
                    }
                </Stack>
            }
            { filters &&
                <Stack className="header-filters" direction="row" spacing={ 2 } sx={ { mt: 1, mb: 3 } }>
                    <Stack direction="row" spacing={ { xs: 0, lg: 6 } } sx={ { py: 5, px: 4 } }>
                        { filters.map(item => (
                            <Stack
                                key={ `header_filter_${ item.label }_${ item.filter.code }` }
                                className={ `header-filter ${ isFilterActive(item.filter.code, item.filterValue) ? 'active' : '' }` }
                                alignItems="center"
                                spacing={ 0.8 }
                                onClick={ () => { handleChange(item.filter.code, item.filterValue); } }
                            >
                                <Avatar
                                    sx={ { width: 84, height: 85 } }
                                    alt={ item.label }
                                    src={ item.image?.file?.url ?? item.label }
                                />
                                <Typography variant="body1">{ item.label }</Typography>
                            </Stack>
                        )) }
                    </Stack>
                </Stack>
            }
        </>
    );
};

export default HeaderContent;
