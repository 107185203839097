// @ts-nocheck
import { isBrowser, isProductionEnv } from './commonHelper';
import { isNil, omitBy } from 'lodash';

interface Delta {
    field_name: string
    from: string | number | null
    to: string | number | null
}

const trackFormStep = (args: any) => {
    // 'trackFormStep' is defined in 'gatsby-ssr.tsx'
    if (typeof window.trackFormStep === 'undefined' && isProductionEnv) return;
    setCustomURL();
    isProductionEnv ? window.trackFormStep(args) : console.log(args);
}

const setCustomURL = () => {
    if (isBrowser && window.snowplow) {
        const url = new URL(window.location.href);
        if (global.micrositeStoreId && !url.searchParams.has('storeId'))
            url.searchParams.append('storeId', global.micrositeStoreId);
        window.snowplow('setCustomUrl', url.toString());
    }
};

export const trackOdinPageView = (pageType: string, url: string) => {
    // snowplow loads by Odin script (@see gatsby-ssr.tsx)
    if (isBrowser && window.snowplow) {
        if (global.micrositeStoreId) {
            url += `${ url.indexOf('?') === -1 ? '?' : '&' }storeId=${ global.micrositeStoreId }`;
        }
        window.snowplow('setCustomUrl', url);
        window.snowplow('setReferrerUrl', window.location.href);
        window.snowplow('trackPageView', pageType);
    }
};

export const trackOdinPlaceOrderButton = () => {
    trackFormStep({
        form_name: 'deli-microsite',
        event_type: 'push_button',
        delta: [],
        form: {
            field_text: 'order online'
        }
    });
};

export const trackOdinOrderItemsPlaceOrderButton = (referenceId, productId) => {
    trackFormStep({
        form_name: 'deli-microsite',
        event_type: 'place_order',
        step_name: 'Place Order',
        delta: [],
        form: {
            reference_id: referenceId,
            product_id: productId
        },
    });
};

export const trackOdinFormStep = (
    eventType: string,
    stepName: string,
    delta: Delta[] = [],
    form: any = {},
    formName: string = 'deli-microsite',
    options: any = {}
) => {
    trackFormStep({
        form_name: formName,
        event_type: eventType,
        step_name: stepName,
        delta,
        form,
        ...options
    });
};

export const trackIvenzaConfiguratorOdinFormStep = (
    configuratorType: string = 'timber',
    eventType: string,
    delta: Delta[] = [],
    form: any = {},
    options: any = {}
) => {
    window.snowplow && window.snowplow('setReferrerUrl', window.previousPath || document.referrer);
    trackFormStep({
        form_name: `ivenza-${ configuratorType }-configurator`,
        event_type: eventType,
        delta,
        form,
        ...options
    });
};

export const trackGridQuestionTile = (title: string, question: string, answer: string, position: number) => {
    trackFormStep({
        form_name: 'deli-question-tile',
        event_type: 'submit_form',
        delta: [],
        form: {
            title,
            question,
            answer,
            position
        }
    });
};

export const trackGridContentTile = (title: string, label: string, url: string, position: number) => {
    trackFormStep({
        form_name: 'deli-content-link-tile',
        event_type: 'submit_form',
        delta: [],
        form: {
            title,
            label,
            url,
            position
        }
    });
};

export const trackWizardEvent = (
    name: string,
    event: string,
    form: Record<string, any> = {},
    delta: Delta[] = [],
    step_name?: string,
    step?: number
) => {
    trackFormStep(
        omitBy({
            form_name: `deli-ms-plp-wizard-${ name.toLowerCase().replaceAll(' ', '_') }`,
            event_type: event,
            delta,
            form,
            step_name,
            step
        }, isNil)
    );
};

export const trackSampleServiceFormEvent = (data: Record<string, any>) => {
    trackFormStep({
        form_name: 'sample_service_form',
        event_type: 'submit_form',
        step_name: 'order sample',
        delta: [],
        form: data,
    });
};
