import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

const initialState = {
    retailer: '',
    locale: ''
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setRetailer: (state, action) => {
            state.retailer = action.payload;
        },
        setLocale: (state, action) => {
            state.locale = action.payload;
        }
    }
});

export const {
    setRetailer,
    setLocale
} = appSlice.actions;

export const selectRetailer = (state: RootState) => state.app.retailer;
export const selectLocale = (state: RootState) => state.app.locale;

export default appSlice.reducer;