import { createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import Signika from '@fonts/Signika.woff2';

const primaryColor = '#e30613';

const palette = {
    primary: {
        main: primaryColor,
        light: '#f7f7f7',
        lightTwo: '#fce8e9',
        dark: primaryColor,
    },
    secondary: {
        main: '#78909c',
        dark: '#37474f',
    },
    discount: {
        text: primaryColor,
        label: primaryColor,
    },
    icons: {
        usp: '#89b203',
    },
    white: {
        main: '#fff',
    },
    wizard: {
        dark: primaryColor
    }
};

let configurator = {
    toggleMore: {
        text: {
            primary: palette.primary.main
        }
    },
    tooltip: {
        text: {
            primary: palette.primary.main
        }
    },
    checkbox: {
        active: '#89b203'
    },
    cta: {
        main: {
            background: {
                primary: 'linear-gradient(to bottom,rgba(154,189,0,1) 0,rgba(72,164,22,1) 100%)'
            },
            text: {
                primary: palette.white.main
            },
        }
    },
    options: {
        button: {
            border: {
                active: palette.primary.main
            },
            text: {
                second: {
                    primary: '#78909c',
                    active: '#78909c'
                }
            }
        },
        chip: {
            background: {
                active: palette.primary.lightTwo
            },
            border: {
                active: palette.primary.lightTwo
            },
            text: {
                active: '#78909c'
            }
        },
        radio: {
            border: {
                active: palette.primary.main
            },
            control: {
                active: palette.primary.main
            },
            text: {
                second: {
                    primary: '#78909c',
                    active: '#78909c'
                }
            },
        },
        card: {
            border: {
                active: palette.primary.main
            },
            text: {
                main: {
                    primary: '#37474f',
                    active: '#37474f'
                },
                second: {
                    primary: '#78909c',
                    active: '#78909c'
                }
            }
        },
        check: {
            border: {
                active: palette.primary.main
            }
        },
        tile: {
            border: {
                active: palette.primary.main
            },
            text: {
                main: {
                    primary: '#78909c',
                    active: '#78909c'
                }
            },
            mark: {
                background: {
                    primary: palette.primary.main,
                    active: palette.primary.main
                }
            },
            label: {
                background: {
                    primary: palette.primary.lightTwo,
                    active: palette.primary.lightTwo
                },
                text: {
                    primary: '#37474f',
                    active: '#37474f'
                }
            }
        },
        slider: {
            rail: {
                background: {
                    primary: palette.primary.lightTwo
                }
            },
            text: {
                primary: '#78909c',
                active: '#78909c'
            },
            mark: {
                background: {
                    primary: palette.primary.main
                }
            },
            thumb: {
                background: {
                    primary: palette.primary.main
                }
            },
            valueLabel: {
                background: {
                    primary: palette.primary.main
                },
            },
            button: {
                svg: {
                    primary: '#78909c'
                }
            },
        },
    }
}

let paletteTheme =  {
    ...palette,
    configurator: {
        ...configurator
    }
}

let entrepotTheme = {
    palette: {
        ...paletteTheme
    },
    typography: {
        ...createFontFamily(
            'Signika',
            {
                fontSize: 16,
                h4: {
                    fontSize: '2rem',
                },
                h5: {
                    fontSize: '1.5rem',
                },
            },
        ),
        color: palette.secondary.main,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 768,
            lg: 992,
            xl: 1200,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'Signika';
                  src: url('${ Signika }') format('woff2');
                }
                body { 
                    -webkit-overflow-scrolling:touch
                }
            `,
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: palette.secondary.main,
                    '&.product-subtitle, &[aria-label="pdp-title-category"]': {
                        color: palette.secondary.main,
                    },
                    '&.product-title, &[aria-label="pdp-title-price"]': {
                        color: palette.secondary.dark,
                    },
                },
            },
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    '&.sawlist-right': {
                        '.MuiTypography-root': {
                            strong: {
                                color: palette.secondary.dark
                            }
                        }
                    }
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '.check-icon, .delivery-icon': {
                        path: {
                            fill: palette.icons.usp,
                        },
                    },
                    '.delivery-icon': {
                        width: '18px!important',
                    },
                    '&.header-content': {
                        h4: {
                            color: palette.secondary.dark,
                        },
                    },
                    '.tooltip-icon': {
                        svg: {
                            color: palette.secondary.main,
                        },
                    },
                    '.tooltip-popover': {
                        h2: {
                            color: palette.secondary.dark,
                        },
                    },
                    '.price-slider': {
                        '.MuiSlider-root': {
                            color: palette.secondary.main,
                        },
                        '.MuiSlider-mark': {
                            backgroundColor: palette.secondary.main,
                        },
                    },
                    '.MuiTable-root td, [aria-label="pdp-text"], [aria-label="bullets"]': {
                        color: palette.secondary.main,
                    },
                    '[aria-label="pdp-go-back"]': {
                        path: {
                            color: palette.secondary.main,
                        }
                    },
                    '.usp-object': {
                        '[data-testid="CheckCircleRoundedIcon"]': {
                            color: palette.icons.usp
                        }
                    },
                    '&.order-overview-top .MuiTypography-body1': {
                        color: palette.secondary.main,
                    }
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&.MuiButton-contained,&.add-to-sawlist-gamma': {
                        padding: '8px 12px',
                        borderRadius: '6px 6px 6px 0',
                        whiteSpace: 'nowrap',
                    },
                    '&.go-to-product': {
                        background: 'linear-gradient(to bottom,rgba(154,189,0,1) 0,rgba(72,164,22,1) 100%)',
                    },
                    '&[aria-label="title-collapse"]': {
                        color: palette.secondary.main,
                    },
                    '&[aria-label="show-more"]': {
                        color: palette.secondary.dark,
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    'label, #sorting-select, .MuiSvgIcon-root': {
                        color: palette.secondary.main,
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    border: '1px solid #e6e6e6',
                    borderRadius: '40px 40px 40px 0',
                    padding: 0,
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPagination-ul': {
                        'li:first-of-type, li:last-of-type': {
                            '.MuiButtonBase-root': {
                                color: palette.secondary.main,
                            },
                        },
                        'li': {
                            '.MuiPaginationItem-root': {
                                fontSize: '1rem',
                                border: 0,
                                borderRadius: '50%',
                                color: palette.secondary.main,
                                '&:hover, &.Mui-selected': {
                                    '&:not(.MuiPaginationItem-ellipsis)': {
                                        backgroundColor: `${ palette.icons.usp }!important`,
                                        border: 0,
                                        color: palette.white.main,
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
};

let theme: any = createTheme(BaseTheme, entrepotTheme);

export default createTheme(theme, ThemeComponents(theme));
