import React, { useState, useMemo } from 'react';
import { useRetailer } from '@hooks/common';
import { prepareRetailerName } from '@helpers';

interface RetailerComponentProps {
    props: any,
    component: {
        dirname: string,
        name: string,
        element: any,
    },
    isLoading?: boolean,
    skeleton?: any
}

const RetailerComponent = ({
    props,
    component,
    isLoading = false,
    skeleton = null
}: RetailerComponentProps) => {
    const retailer = prepareRetailerName(useRetailer());
    const SkeletonComponent = () => skeleton;
    const [ Component, setComponent ] = useState(() => SkeletonComponent);

    useMemo(() => {
        import(`./${ component.dirname }/retailer/${ retailer }/${ component.name }`)
            .then(retailerComponent => setComponent(() => retailerComponent.default))
            .catch(() => setComponent(() => component.element));

        return () => setComponent(() => SkeletonComponent);
    }, [ retailer ]);

    return isLoading
        ? <SkeletonComponent/>
        : <Component { ...props }/>;
};

export default RetailerComponent;
