import { getLocalStorage } from "./commonHelper";
import { prepareRetailerName } from "./retailersHelper";

const
    gridKey = 'gridSettings',
    ls = getLocalStorage(),
    mapKey = (retailer: string) => `${ prepareRetailerName(retailer).toLowerCase() }_${ gridKey }`;

interface GridSettings {
    ignoredTiles: string[];
}

const GridHelper = {
    getGridSettings: (retailer: string): GridSettings => {
        const
            key = mapKey(retailer),
            settings = ls.get(key);

        if (settings)
            return settings;

        ls.set(key, { ignoredTiles: [] });

        return ls.get(key);
    },
    addIgnoredGridTile: (retailer: string, tileId: string): boolean => {
        const settings = GridHelper.getGridSettings(retailer);
        settings.ignoredTiles.push(tileId);

        return ls.set(mapKey(retailer), settings);
    }
};

export default GridHelper;