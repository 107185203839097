import React, { useState } from 'react';
import { Button, FormControl, FormHelperText, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import { useMicrocopy } from '@hooks/common';
import { useDimensions } from '@hooks/catalog';

const DimensionsFilter = ({ onChange, maxDimensions }) => {
    const
        microcopy = useMicrocopy(),
        { dimensions, setDimensions } = useDimensions(),
        maxLength = maxDimensions?.configurator_input_max_length / 10,
        maxWidth = maxDimensions?.configurator_input_max_width / 10;

    const
        [ length, setLength ] = useState(dimensions['length'] ?? ''),
        [ width, setWidth ] = useState(dimensions['width'] ?? '');

    const handleDimensions = () => {
        setDimensions({ length, width });
        onChange({ length, width });
    };

    const
        checkLength = () => length > maxLength && maxLength != 0,
        checkWidth = () => width > maxWidth && maxWidth != 0;

    const
        EndAdornment = (
            <InputAdornment aria-label="dimension-adornment" position="end">
                { microcopy.get('catalog.filter.dimension.units_of_length', 'cm') }
            </InputAdornment>
        ),
        ErrorMessage = ({ value }) => (
            <Typography className="error-message">
                { microcopy.get('catalog.filter.max_dimensions', 'Max is') }
                <Typography component="span" sx={ { px: 0.4, fontSize: 10 } }>{ value }</Typography>
                { microcopy.get('catalog.filter.dimension.units_of_length', 'cm') }
            </Typography>
        );

    return (
        <Stack alignItems="normal" flexWrap="wrap">
            <Stack direction="row" spacing={ 2 } sx={ { flex: 1 } }>
                <FormControl variant="outlined" aria-label="dimension-length" sx={{ position: 'relative' }}>
                    <FormHelperText aria-label="dimension-helper-text" id="outlined-length-helper-text">
                        { microcopy.get('catalog.filter.dimension.length_title', 'Length') }
                    </FormHelperText>
                    <OutlinedInput
                        type="number"
                        id="outlined-adornment-length"
                        value={ length }
                        name="dimension-length"
                        aria-describedby="outlined-length-helper-text"
                        aria-label="dimension-length"
                        inputProps = {{ 'aria-label': 'dimension-length' }}
                        onChange={ event => setLength(event.target.value) }
                        error={ length > maxLength }
                        endAdornment={ EndAdornment }
                    />
                    { checkLength() && <ErrorMessage value={ maxLength }/> }
                </FormControl>
                <FormControl variant="outlined" aria-label="dimension-width" sx={{ position: 'relative' }}>
                    <FormHelperText aria-label="dimension-helper-text" id="outlined-weight-helper-text">
                        { microcopy.get('catalog.filter.dimension.width_title', 'Width') }
                    </FormHelperText>
                    <OutlinedInput
                        type="number"
                        id="outlined-adornment-weight"
                        value={ width }
                        name="dimension-width"
                        aria-describedby="outlined-weight-helper-text"
                        aria-label="dimension-width"
                        inputProps = {{ 'aria-label': 'dimension-length' }}
                        onChange={ event => setWidth(event.target.value) }
                        error={ width > maxWidth }
                        endAdornment={ EndAdornment }
                    />
                    { checkWidth() && <ErrorMessage value={ maxWidth }/> }
                </FormControl>
            </Stack>
            <Button
                variant="contained"
                aria-label="apply-dimension"
                sx={ { height: 48, px: 1, mt: 2.5, minWidth: 96 } }
                onClick={ handleDimensions }
                disabled={ checkLength() || checkWidth() }
                className="apply-dimensions"
            >
                { microcopy.get('catalog.filter.dimension.apply_button', 'Apply') }
            </Button>
        </Stack>
    );
};

export default DimensionsFilter;
