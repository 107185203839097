import { useMediaQuery } from '@mui/material';
import { useGlobalState } from '@context';
import { NavigationHelper, prepareRetailerName } from '@helpers';
import { useCatalogContext } from '@hooks/catalog';
import { useRef } from 'react';
import { debounce } from 'lodash';

export const useRetailer = (mapName = true) => {
    const [ { retailer } ] = useGlobalState();

    return mapName ? prepareRetailerName(retailer) : retailer;
};

export const useLocale = () => {
    const [ { locale } ] = useGlobalState();

    return locale;
};

export const useTheme = () => {
    const [ { theme } ] = useGlobalState();

    return theme;
};

export const useChannel = () => {
    const [ { channel } ] = useGlobalState();

    return channel;
};

export const useDispatch = () => {
    const [ , dispatch ] = useGlobalState();

    return dispatch;
};

export const useMicrocopy = () => {
    const [ { microcopy } ] = useGlobalState();

    return microcopy;
};

export const useNavigate = () => new NavigationHelper(
    useRetailer(false),
    useLocale(),
    useDispatch(),
    useCatalogContext(),
);

export const useIsMobile = (key: any = 'md') => {
    const [ { theme } ] = useGlobalState();

    return useMediaQuery(theme.breakpoints.down(key));
};

export const useRetailerLogo = () => {
    const [ { retailerLogo } ] = useGlobalState();

    return retailerLogo;
};


export const useDebounced = (callback: any, timeout = 300) => useRef(debounce(callback, timeout)).current;

export const useRetailerLike = (retailers: string | string[], retailer = useRetailer()) => {
    const regex = (search: string) => `(?:^|\\W|[a-z])${ search }(?:$|\\W|[A-Z])`;

    return (Array.isArray(retailers) ? retailers : [ retailers ])
        .find(item => retailer.match(regex(item)));
};

export const useIframeMessage = () => {
    const [ { iframeMessage }, dispatch ] = useGlobalState();

    return {
        iframeMessage,
        clearIframeMessage: () => dispatch({ type: 'SET_IFRAME_MESSAGE', message: null }),
    };
};

export const useParentUrl = () => {
    const [ { parentUrl } ] = useGlobalState();

    return parentUrl;
};
