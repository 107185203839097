import { navigate } from 'gatsby';
import UrlHelper, { urlPageTypes } from './urlHelper';
import { trackOdinFormStep, trackOdinPageView } from './odinHelper';
import { prepareRetailerName, retailers } from './retailersHelper';
import SawListHelper from './sawListHelper';

export default class NavigationHelper {
    constructor(
        private retailer: string,
        private locale: string,
        private dispatch: any = null,
        private state: any = null
    ) {}

    public toSawList(updateState = false) {
        if (updateState)
            this.dispatchCatalogStateChange(this.state);
        const url = UrlHelper.getUrl(this.retailer, this.locale, urlPageTypes.SawList);
        trackOdinPageView('saw list', url);

        return navigate(url);
    }

    public toCatalog() {
        switch (prepareRetailerName(this.retailer)) {
            case retailers.HuboInsects.name:
            case retailers.HornbachInsects.name:
                this.dispatchCatalogStateChange({});
        }

        const url = UrlHelper.getUrl(this.retailer, this.locale);
        trackOdinPageView('product list page', url);

        return navigate(url);
    }

    public toProduct(id: string, queryParams?: any) {
        this.dispatchCatalogStateChange(this.state);

        let url = UrlHelper.getUrl(this.retailer, this.locale, urlPageTypes.Product, id);
        if(queryParams)
            url += '?' + new URLSearchParams(queryParams).toString();

        trackOdinPageView('product detail page', url);

        return navigate(url);
    }

    public editSawListProduct(id, productId, quotation, referenceId, type) {
        const url = UrlHelper.getUrl(this.retailer, this.locale, urlPageTypes.Product, productId);

        trackOdinFormStep(
            'push_button',
            'edit_item',
            [],
            {
                form: {
                    reference_id: referenceId,
                    type,
                    product_id: productId,
                    sawlist_id: SawListHelper.getSawListUid(this.retailer)
                }
            }
        );
        trackOdinPageView('product detail page', url);

        return navigate(url, {
            state: {
                sawListItemId: id,
                quotation
            }
        });
    }

    private dispatchCatalogStateChange(state: any) {
        if (!state) return;
        if (!this.dispatch) throw 'Dispatch is not set';

        this.dispatch({
            type: 'SET_CATALOG_STATE',
            catalogState: {
                prevUrl: location.href.replace(location.origin, ''),
                ...state
            }
        });
    }

    public toContentPage(id: string) {
        this.dispatchCatalogStateChange(this.state);
        const url = UrlHelper.getUrl(this.retailer, this.locale, urlPageTypes.ContentPage, id);
        trackOdinPageView('content page', url);

        return navigate(url);
    }
}
