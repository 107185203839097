import { prepareUrlLocale } from './commonHelper';

export const urlPageTypes = {
    Product: 'p',
    SawList: 'sawlist',
    ContentPage: 'page',
    SampleService: 'sample-service',
};

const UrlHelper = {
    getUrl: (
        retailer: string,
        locale: string,
        type?: string,
        code?: string
    ): string =>
        `/${ retailer.toLowerCase() }/${ prepareUrlLocale(locale) }`
        + `${ type ? `/${ type }` : '' }`
        + `${ code ? `/${ code }` : '' }`
};

export const getUrlOrigin = url => (new URL(url)).origin;

export default UrlHelper;
