import React from 'react';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Snackbar } from '@mui/material';
import { useMicrocopy } from '@hooks/common';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
    (props, ref) =>
        <MuiAlert sx={ { mt: 4 } } elevation={ 6 } ref={ ref } variant="filled" { ...props } />
);

interface AlertSnackBarProps {
    open: boolean;
    onClose: () => void
}

export const AlertSnackBar = ({ open = false, onClose }: AlertSnackBarProps) => {
    const microcopy = useMicrocopy();

    return (
        <Snackbar
            open={ open }
            onClose={ onClose }
            autoHideDuration={ 10000 }
            anchorOrigin={ { vertical: 'top', horizontal: 'right' } }
        >
            <Alert severity="error" onClose={ onClose }>
                { microcopy.get(
                    'pdp.add_to_sawlist_error_text',
                    'Sorry, something went wrong. Please contact the administrator.'
                ) }
            </Alert>
        </Snackbar>
    );
}

export const AlertBox = ({ open = false, onClose }: AlertSnackBarProps) => {
    const microcopy = useMicrocopy();

    if (!open) {
        return;
    }

    return (
        <Alert severity="error" onClose={ onClose }>
            { microcopy.get(
                'pdp.add_to_sawlist_error_text',
                'Sorry, something went wrong. Please contact the administrator.'
            ) }
        </Alert>
    );
}
