import React from 'react';
import type { ContactFormSampleGroup, ContentfulSampleGroup } from '@models';

export type SamplesFormResult = 'success' | 'error' | null;

interface SampleServiceContextProps {
    samples: ContentfulSampleGroup[];
    samplesNl: { groups: Record<string, string>, samples: Record<string, string> };
    maxSamplesCount: number;
    selectedSamples: ContactFormSampleGroup[];
    setSelectedSamples: React.Dispatch<React.SetStateAction<ContactFormSampleGroup[]>>;
    selectedSamplesCount: number;
    setSelectedSamplesCount: React.Dispatch<React.SetStateAction<number>>;
    showResult: SamplesFormResult;
    setShowResult: React.Dispatch<React.SetStateAction<SamplesFormResult>>;
}

export const defaultContext: SampleServiceContextProps = {
    samples: [],
    samplesNl: { groups: {}, samples: {} },
    maxSamplesCount: 1,
    selectedSamples: [],
    setSelectedSamples: () => {},
    selectedSamplesCount: 0,
    setSelectedSamplesCount: () => {},
    showResult: null,
    setShowResult: () => {},
};
export const SampleServiceContext = React.createContext(defaultContext);

export const SampleServiceProvider = ({ data, children }) => {
    const
        [ selectedSamples, setSelectedSamples ] = React.useState<Record<string, any>[]>([]),
        [ selectedSamplesCount, setSelectedSamplesCount ] = React.useState<number>(0);

    return (
        <SampleServiceContext.Provider
            value={ {
                selectedSamples,
                setSelectedSamples,
                selectedSamplesCount,
                setSelectedSamplesCount,
                ...data,
            } }
        >
            { children }
        </SampleServiceContext.Provider>
    );
};
