import React from 'react';
import { useGlobalState } from '@context';
import { Container, CssBaseline, ScopedCssBaseline } from '@mui/material';
import { Div, Header } from '@components/Common';
import { pageTypes } from '@helpers';

const Layout = ({ children, ...props }) => {
    const [ { site } ] = useGlobalState();

    const headerAllowedPageTypes = [
        pageTypes.Catalog,
        pageTypes.PDP,
        pageTypes.SawList
    ];

    return (
        <Div id="microsite" sx={ { py: 2 } }>
            <CssBaseline/>
            <ScopedCssBaseline>
                <title>{ site.title }</title>
                { headerAllowedPageTypes.includes(props.pageContext.pageType) &&
                    <Container maxWidth="xl" id="header">
                        <Header pageContext={ props.pageContext }/>
                    </Container>
                }
                { children }
            </ScopedCssBaseline>
        </Div>
    );
};

export default Layout;
