import React, { useState } from 'react';
import { Box, Button, Card, CardActionArea, CardContent, Container, Divider, Stack, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import type { IDecisionTreeQuestion } from '@models';
import { LazyLoadImage, priceSymbol, RichText, Tooltip } from '@components/Common';
import { WizardService } from '@services/Wizard';
import { useActiveFilters, useIsLoading, useTotalCount } from '@hooks/catalog';
import { useIsMobile, useLocale, useMicrocopy } from '@hooks/common';
import { scrollTo, trackWizardEvent } from '@helpers';

export interface WizardProps {
    onChange: any,
    tree: IDecisionTreeQuestion,
    name: string,
    prices: {
        min: number | null,
        max: number | null
    }
}

const Wizard = ({
    onChange,
    tree,
    name,
    prices
}: WizardProps) => {
    const
        activeFilters = useActiveFilters(),
        isLoading = useIsLoading(),
        isMobile = useIsMobile(),
        locale = useLocale(),
        microcopy = useMicrocopy(),
        totalCount = useTotalCount(),
        [ selected, setSelected ] = useState(''),
        getImageHeight = () => isMobile ? 150 : 200;

    const wizard = new WizardService(locale, tree, activeFilters);
    const question = wizard.getActiveQuestion();
    const answer = wizard.getActiveAnswer();

    const makeFilterValue = (value) => {
        return value;
        // const parsedValues = value.split(';');
        // return parsedValues.length > 1 ? parsedValues : value;
    }

    const selectedValues = (code: string, value: string) => {
        let selected = wizard.getActiveSelection();
        selected[code] = value;

        return selected;
    }

    const prevQuestion = () => {
        if (!answer?.parent) return;

        wizard.back();
        setSelected('');
        const code = wizard.getActiveCode()!;

        trackWizardEvent(
            name,
            'navigation',
            {},
            [{
                field_name: 'Prev',
                from: null,
                to: 1
            }],
            code,
            wizard.getActiveCodes().length + 1
        );

        onChange({
            ...activeFilters,
            [code]: null
        });
    };

    const selectAnswer = (value: string) => {
        const code = wizard.getActiveCode()!;
        trackWizardEvent(
            name,
            'change_form',
            selectedValues(code, value),
            [{
                field_name: wizard.getActiveCode()!,
                from: null,
                to: value
            }],
            code,
            wizard.getActiveCodes().length + 1
        );

        if (isMobile) return setSelected(value);
        wizard.select(value);

        onChange({
            ...activeFilters,
            [code]: makeFilterValue(value)
        });
    };

    const nextQuestion = () => {
        if (!selected) return;

        const code = wizard.getActiveCode()!;
        wizard.select(selected);
        setSelected('');

        trackWizardEvent(
            name,
            'navigation',
            {},
            [{
                field_name: 'Next',
                from: null,
                to: 1
            }],
            wizard.getActiveCode()!,
            wizard.getActiveCodes().length + 1
        );

        onChange({
            ...activeFilters,
            [code]: makeFilterValue(selected)
        });
    };

    const resetWizard = () => {
        const filters = wizard.getActiveCodes().reduce((prev, current) => {
            prev[current] = null;
            return prev;
        }, {});
        wizard.reset();
        trackWizardEvent(name, 'reset_wizard', {
            results: totalCount,
            price_from: prices.min,
            price_to: prices.max
        });

        onChange({ ...activeFilters, ...filters });
    };

    const toResults = () => {
        trackWizardEvent(name, 'show_results', {
            results: totalCount,
            price_from: prices.min,
            price_to: prices.max
        });
        scrollTo(document.getElementById('catalog'));
    }

    const RenderResetButton = () => (
        <Button className="wizard-btn navigation with-icon" variant="contained" onClick={ resetWizard }>
            <RestartAltIcon/>
            <Typography component="span">
                { microcopy.get('catalog.wizard.reset', 'Reset') }
            </Typography>
        </Button>
    );

    const RenderNextButton = () => (
        <Button
            variant="contained"
            className={ 'wizard-btn navigation with-icon ' + (selected ? '' : 'disabled') }
            disabled={ !selected }
            onClick={ nextQuestion }
        >
            <Typography component="span">
                { microcopy.get('catalog.wizard.next', 'Next') }
            </Typography>
            <KeyboardArrowRightIcon/>
        </Button>
    );

    const RenderBackButton = () => (
        <Button
            variant="contained"
            className={ 'wizard-btn navigation with-icon ' + (answer ? '' : 'disabled') }
            disabled={ !answer }
            onClick={ prevQuestion }
        >
            <KeyboardArrowLeftIcon/>
            <Typography component="span">
                { microcopy.get('catalog.wizard.back', 'Back') }
            </Typography>
        </Button>
    );

    const RenderSummaryBlock = () => (
        <Stack>
            <Typography className="wizard-typography bold">
                { totalCount + ' ' + microcopy.get('catalog.grid.total_count', 'results') }
            </Typography>

            { prices.min && prices.max &&
                <Typography>
                    { microcopy.get('catalog.filter.price.from', 'from') } { priceSymbol }{ prices.min }&nbsp;
                    { microcopy.get('catalog.filter.price.to', 'to') } { priceSymbol }{ prices.max }
                </Typography>
            }
        </Stack>
    );

    const RenderResultsButton = () => (
        <Button className="wizard-btn navigation results" variant="contained" onClick={ toResults }>
            { microcopy.get('catalog.wizard.result_button', 'Result') }
        </Button>
    );

    return (
        <Container maxWidth="xl" className="wizard-container" sx={ isLoading ? {
            opacity: 0.5,
            pointerEvents: 'none'
        } : {} }
        >
            <Stack className={ 'wizard ' + (question ? '' : 'centered') }>
                { question ? (
                    <Stack direction="column">
                        <Typography className="question-title">
                            { question.title }
                        </Typography>

                        <RichText className="question-description" value={ question.description }/>

                        <Stack direction="row" className="answers">
                            { question.answers.map((answer) =>
                                <Stack position="relative" height="100%" key={ answer.title }>
                                    { answer.tooltip &&
                                        <Stack className="wizard-stack tooltip">
                                            <Tooltip
                                                title={ answer.title }
                                                content={ answer.tooltip as any }
                                                popupOnly={ true }
                                                code="wizard"
                                            />
                                        </Stack>
                                    }
                                    <Card className={ 'tooltip-container answer ' + (selected === answer.attributeValue ? 'selected' : '') }>
                                        <CardActionArea
                                            component="div"
                                            onClick={ () => { selectAnswer(answer.attributeValue) } }
                                        >
                                            <Stack direction="column" height="100%">
                                                { !isMobile &&
                                                    <Box className="answer-image">
                                                        <LazyLoadImage
                                                            src={ answer.image?.file.url }
                                                            alt={ question.title }
                                                            height={ getImageHeight() }
                                                        />
                                                    </Box>
                                                }
                                                <CardContent className="answer-content">
                                                    <Stack direction="row" alignItems="center">
                                                        <CheckIcon className="radio-button"/>
                                                        <Typography className="answer-title">
                                                            { answer.title }
                                                        </Typography>
                                                    </Stack>

                                                    <RichText value={ answer.description } className="answer-subtitle"/>
                                                </CardContent>
                                            </Stack>
                                        </CardActionArea>
                                    </Card>
                                </Stack>
                            ) }
                        </Stack>
                    </Stack>
                ) : (
                    <Stack direction="column" className="wizard-stack centered" gap="20px">
                        <CheckCircleOutlineIcon className="wizard-icon large"/>
                        <Typography component="span" className="wizard-typography bold large">
                            { totalCount + ' ' + microcopy.get('catalog.grid.total_count', 'results') }
                        </Typography>
                        <Typography component="span" maxWidth="220px" align="center">
                            { microcopy.get('catalog.wizard.result_text', 'Result text') }
                        </Typography>
                    </Stack>
                ) }
            </Stack>

            <Divider/>

            <Stack direction="row" className="control-container">
                <Stack direction="row" className="controls control-left">
                    <RenderBackButton/>

                    { isMobile
                        ? (question ? <RenderNextButton/> : <RenderResetButton/>)
                        : (answer &&
                            <>
                                <RenderResetButton/>
                                <Divider orientation="vertical" flexItem/>
                            </>
                        )
                    }
                </Stack>
                <Stack direction="row" className="controls control-right">
                    <RenderSummaryBlock/>

                    <RenderResultsButton/>
                </Stack>
            </Stack>
        </Container>
    );
};

export default Wizard;
