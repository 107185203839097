import React from 'react';
import { Grid } from '@mui/material';
import { pickBy, includes } from 'lodash';
import { Product } from '@components/Catalog';
import { useChannel, useRetailer } from '@hooks/common';
import { useCatalogItemsToShow, useIsLoading } from '@hooks/catalog';
import ContentTile from '@components/Catalog/Tiles/ContentTile';
import { IContentfulGridTile } from '@models';
import { GridTiles } from '@helpers';
import FeedbackTile from '@components/Catalog/Tiles/FeedbackTile';
import { useGridSkeleton } from '@hooks/skeleton/catalog';
import { RetailerComponent } from '@components';

const GridList = ({ items, variants, tiles }) => {
    const
        retailer = useRetailer(),
        channel = useChannel(),
        itemsToShow = useCatalogItemsToShow(retailer, items, tiles);

    const RenderItem = ({ item }: { item: any | IContentfulGridTile }) => {
        const commonProps = {
            key: item.id,
            data: item
        };
        switch (item.entryType) {
            case GridTiles.contentTile:
                return <ContentTile { ...commonProps }/>;
            case GridTiles.feedbackTile:
                return <FeedbackTile { ...commonProps }/>;
            default:
                return (
                    <Product
                        { ...commonProps }
                        thickness_variants={
                            pickBy(variants, (value, key) => includes(
                                item.thuja_variants?.find(i => i.channel === channel)?.entities,
                                key
                            ))
                        }
                    />
                );
        }
    };

    return (
        <Grid container spacing={ 2 } className="grid-list">
            { itemsToShow.map((item: any | IContentfulGridTile) =>
                <Grid item xs={ 12 } sm={ 8 } md={ 9 } lg={ 6 } xl={ 4 } key={ item.id }>
                    <RenderItem item={ item }/>
                </Grid>
            ) }
        </Grid>
    );
};

export default (props: {
    items: [],
    variants: any,
    tiles: any[]
}) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Catalog',
            name: 'GridList',
            element: GridList
        } }
        skeleton={ useGridSkeleton(props.items.length) }
        isLoading={ useIsLoading() }
    />;
