import React from 'react';
import { Box, Typography } from '@mui/material';
import { useMicrocopy } from '@hooks/common';
import { mapFilterValue } from '@helpers';
import { Price } from '@components/Common';
import { useActiveFilters } from '@hooks/catalog';

const ActiveFiltersContent = ({ filter }) => {
    const
        microcopy = useMicrocopy(),
        activeFilters = useActiveFilters();

    let values: any[] = [];

    switch(filter) {
        case 'price':
            return (
                <Box component="span">
                    { activeFilters[filter][0] &&
                        <Box component="span">
                            { microcopy.get('catalog.filter.price.from', 'from') }&nbsp;
                            <Price price={ activeFilters[filter][0] }/>&nbsp;
                        </Box> }
                    { activeFilters[filter][1] &&
                        <Box component="span">
                            { microcopy.get('catalog.filter.price.to', 'to') }&nbsp;
                            <Price price={ activeFilters[filter][1] }/>
                        </Box> }
                </Box>
            );
        case 'configurator_input_max_length':
            values = [
                microcopy.get('catalog.filter.dimension.length_title', 'Length'),
                activeFilters[filter] / 10,
                'cm'
            ];

            return (<>{ activeFilters[filter] ? values.join(' ') : '' }</>);
        case 'configurator_input_max_width':
            values = [
                microcopy.get('catalog.filter.dimension.width_title', 'Width'),
                activeFilters[filter] / 10,
                'cm'
            ];

            return (<>{ activeFilters[filter] ? values.join(' ') : '' }</>);
        default:
            return (
                <Box component="span">
                    { String(activeFilters[filter])
                        .split(',')
                        .map((value, index, { length }) => (
                                <Typography
                                    key={ `filter_${ filter }_value_${ value }` }
                                    sx={ { pb: length - 1 === index ? 0 : 1 / 2, lineHeight: '1.2', fontSize: 'inherit' } }
                                    className="filter-value"
                                >
                                    { mapFilterValue(microcopy, filter, value) }
                                </Typography>
                            )
                        )
                    }
                </Box>
            );
    }
};

export default ActiveFiltersContent;
