import { IDecisionTreeAnswer, IDecisionTreeQuestion } from '@models';

export default class WizardService {
    private activeQuestion?: IDecisionTreeQuestion;
    private activeAnswer?: IDecisionTreeAnswer;

    constructor(private locale: string, private tree: IDecisionTreeQuestion, activeFilters: Record<string, string>) {
        this.setActiveQuestion(this.tree);
        this.initQuestion(this.tree);
        this.recursiveInit(locale, this.mapFilters(activeFilters));
    }

    private mapFilters(activeFilters: Record<string, string>): Record<string, string> {
        return Object.keys(activeFilters).reduce((prev, filter) => {
            prev[this.fieldNoLocale(filter)] = activeFilters[filter];
            return prev;
        }, {})
    }

    private recursiveInit(locale: string, activeFilters: Record<string, string>): void {
        if (this.activeQuestion && activeFilters[this.activeQuestion.gridFilter.code]) {
            const value = activeFilters[this.activeQuestion.gridFilter.code];
            const answer = this.activeQuestion.answers.find(child => child.attributeValue === value);
            this.setActiveAnswer(answer);
            this.setActiveQuestion(answer?.question);

            if (answer?.question) this.recursiveInit(locale, activeFilters);
        }
    }

    private initQuestion(question: IDecisionTreeQuestion): void {
        for (const answer of question.answers) {
            answer.parent = question;
            this.initAnswer(answer);
        }
    }

    private initAnswer(answer: IDecisionTreeAnswer): void {
        if (!answer.question) return;
        answer.question.parent = answer;
        this.initQuestion(answer.question);
    }

    private setActiveAnswer(answer: IDecisionTreeAnswer | undefined): void {
        this.activeAnswer = answer;
    }

    private setActiveQuestion(question: IDecisionTreeQuestion | undefined): void {
        this.activeQuestion = question;
    }

    private fieldInLocale(attr: string): string {
        return `${ attr }_${ this.locale }`;
    }

    private fieldNoLocale(attr: string): string {
        return attr.replace(`_${ this.locale }`, '');
    }

    getActiveQuestion(): IDecisionTreeQuestion | undefined {
        return this.activeQuestion;
    }

    getActiveAnswer(): IDecisionTreeAnswer | undefined {
        return this.activeAnswer;
    }

    getActiveCode(): string | null {
        if (!this.activeQuestion) return null;
        return this.fieldInLocale(this.activeQuestion.gridFilter.code);
    }

    getActiveCodes(): string[] {
        const codes: string[] = [];
        let answer = this.activeAnswer;
        while (answer) {
            if (answer.parent) codes.push(this.fieldInLocale(answer.parent.gridFilter.code));
            answer = answer.parent?.parent;
        }

        return codes;
    }

    getActiveSelection(): Record<string, string> {
        const selection: Record<string, string> = {};

        let answer = this.activeAnswer;
        while (answer) {
            if (answer.parent) selection[this.fieldInLocale(answer.parent.gridFilter.code)] = answer.attributeValue;
            answer = answer.parent?.parent;
        }

        return selection;
    }

    select(value: string): void {
        if (!this.activeQuestion) return;

        const answer = this.activeQuestion.answers.find(child => child.attributeValue === value);
        if(!answer) return;

        this.setActiveAnswer(answer);
        this.setActiveQuestion(answer.question);
    }

    back(): void {
        this.setActiveQuestion(this.activeAnswer?.parent);
        this.setActiveAnswer(this.activeQuestion?.parent);
    }

    reset(): void {
        this.setActiveAnswer(undefined);
        this.setActiveQuestion(this.tree);
    }
}
