import React from 'react';
import { Pagination as MuiPagination, Stack } from '@mui/material';
import { useIsMobile } from '@hooks/common';
import { useIsLoading } from '@hooks/catalog';
import { usePaginationSkeleton } from '@hooks/skeleton/catalog';

const Pagination = ({ count, page, onChange }) => {
    const
        isMobile = useIsMobile('md'),
        isLoading = useIsLoading(),
        skeleton = usePaginationSkeleton();

    return (
        <Stack justifyContent="center" direction="row" sx={ { mt: 3, mb: 4 } }>
            { isLoading
                ? skeleton
                : <MuiPagination
                    variant="outlined"
                    shape="rounded"
                    defaultPage={ 1 }
                    page={ page }
                    count={ count }
                    onChange={ onChange }
                    siblingCount={ isMobile ? 0 : 1 }
                />
            }
        </Stack>
    );
};

export default Pagination;
