import { createTheme } from '@mui/material';
import BaseTheme, { createFontFamily } from './base/base';
import ThemeComponents from './base/components';
import TradeGothicHornbach from '@fonts/TradeGothicHornbach.woff2';
import BadHouseHornbachBold from '@fonts/BadHouseHornbach-Bold.woff2';

const palette = {
    primary: {
        main: '#f99d27',
        light: '#f28e001a',
        lightTwo: '#feeede',
        dark: '#f28e00',
        darken: '#b96000',
    },
    secondary: {
        main: '#66dc80',
    },
    checklist: {
        main: '#f7901e',
        light: '#fdc689',
    },
    grey: {
        100: '#f7eddf',
    },
    white: {
        main: '#fff',
    },
    wizard: {
        dark: '#f99d27',
    },
};

let configurator = {
    toggleMore: {
        text: {
            primary: palette.primary.main,
        },
    },
    tooltip: {
        text: {
            primary: palette.primary.main,
        },
    },
    checkbox: {
        active: palette.primary.main,
    },
    cta: {
        main: {
            background: {
                primary: palette.primary.main,
            },
            text: {
                primary: palette.white.main,
            },
        },
    },
    options: {
        button: {
            border: {
                active: palette.primary.main,
            },
        },
        chip: {
            background: {
                active: palette.primary.lightTwo,
            },
            border: {
                active: palette.primary.lightTwo,
            },
            text: {
                active: '#000',
            },
        },
        radio: {
            border: {
                active: palette.primary.main,
            },
            control: {
                active: palette.primary.main,
            },
        },
        card: {
            border: {
                active: palette.primary.main,
            },
        },
        check: {
            border: {
                active: palette.primary.main,
            },
        },
        tile: {
            border: {
                active: palette.primary.main,
            },
            mark: {
                background: {
                    primary: palette.primary.main,
                    active: palette.primary.main,
                },
            },
            label: {
                background: {
                    primary: palette.primary.lightTwo,
                    active: palette.primary.lightTwo,
                },
                text: {
                    primary: '#000',
                    active: '#000',
                },
            },
        },
        slider: {
            rail: {
                background: {
                    primary: palette.primary.lightTwo,
                },
            },
            mark: {
                background: {
                    primary: palette.primary.main,
                },
            },
            thumb: {
                background: {
                    primary: palette.primary.main,
                },
            },
            valueLabel: {
                background: {
                    primary: palette.primary.main,
                },
            },
        },
    },
};

let paletteTheme = {
    ...palette,
    configurator: {
        ...configurator,
    },
};

let hornbachTheme = {
    palette: {
        ...paletteTheme,
    },
    typography: {
        ...createFontFamily('TradeGothicHornbach'),
        fontSize: 16,
        h4: {
            fontWeight: 600,
            fontSize: '2.25rem',
        },
        h6: {
            fontSize: '1.15rem',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'TradeGothicHornbach';
                  src: url('${ TradeGothicHornbach }') format('woff2');
                }
                @font-face {
                  font-family: 'TradeGothicHornbach';
                  src: url('${ BadHouseHornbachBold }') format('woff2');
                  font-weight: bold;
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
            `,
        },
        MuiGrid: {
            styleOverrides: {
                root: {
                    '&.filter-block': {
                        paddingTop: '0!important',
                    },
                },
            },
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    '.tooltip-icon': {
                        '.MuiSvgIcon-root': {
                            path: {
                                color: '#333',
                            },
                        },
                    },
                    '&[aria-label="pdp-go-back"]': {
                        color: '#505971',
                    },
                    '&.combined-price': {
                        '.price': {
                            color: '#c40075',
                            '.MuiTypography-h5': {
                                fontWeight: 700,
                            },
                        },
                    },
                    '.price': {
                        display: 'inline-flex',
                        flexDirection: 'row-reverse',
                        alignItems: 'center',
                        '.price-symbol': {
                            top: 0,
                            left: 5,
                            fontSize: '1.6em',
                        },
                        small: {
                            fontSize: '1em',
                        },
                    },
                    '&.pdp-promo-accordion': {
                        'div.pdp-promo-accordion-button': {
                            backgroundColor: '#f99d27',
                        },
                        'div.pdp-promo-accordion-collapse': {
                            '&, &.promo': {
                                '.MuiCollapse-wrapperInner': {
                                    borderColor: '#f99d27'
                                }
                            }
                        }
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    // '.price': {
                    //     color: '#c40075',
                    //     display: 'flex',
                    //     flexDirection: 'row-reverse',
                    //     alignItems: 'center',
                    //     '.MuiTypography-h5': {
                    //         fontWeight: 700
                    //     },
                    //     '.price-symbol': {
                    //         top: 0,
                    //         left: 5
                    //     },
                    //     small: {
                    //         fontSize: 28
                    //     }
                    // }
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    '.MuiPaginationItem-root.MuiButtonBase-root': {
                        fontSize: 12,
                        fontWeight: 700,
                        lineHeight: 14,
                        border: `1px solid #E5E5E5`,
                        borderRadius: 2,
                        color: '#71767a',
                        background: '#fafafa',
                        '&:hover, &.Mui-selected': {
                            color: `${ palette.white.main } !important`,
                            background: `${ palette.primary.main } !important`,
                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                            '&:not(.MuiPaginationItem-ellipsis)': {
                                color: `${ palette.white.main } !important`,
                                background: `${ palette.primary.main } !important`,
                                backgroundColor: `${ palette.primary.main } !important`,
                            },
                        },
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '.MuiListItem-root': {
                        fontSize: 16,
                    },
                },
            },
        },
    },
};

let theme: any = createTheme(
    {
        ...BaseTheme,
    },
    hornbachTheme,
);

export default createTheme(theme, ThemeComponents(theme));
